import React, { Component, Fragment ,PureComponent} from 'react';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Spinner from 'Components/Model/Spinner';


import IntlMessages from 'Util/IntlMessages';
import {  injectIntl } from 'react-intl';
import SearchSeeker from 'Components/Model/Seeker/SearchSeeker'
import IconButton from '@material-ui/core/IconButton';
import EditSeekerDetails from "Components/Model/Seeker/EditSeekerDetails";
import FilterAlphabet from 'Components/FilterAlphabet/FilterAlphabet';
import { getListSeeker, getSeekerDetail, showRootSpinner, setSearchText } from 'Actions';



const ColorButton = withStyles(theme => ({
    root: {
      color: '#fff',
      backgroundColor: '#000',
      '&:hover': {
        backgroundColor: '#5f5f5f',
      },
    },
  }))(Button);

const NUMBER_SEEKER_SHOW_ON_SCROLL = 50;

class SeekerModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabFilterActive: 'A - F',
            searchText: '',
            isShowEditForm: 0,
            limit: NUMBER_SEEKER_SHOW_ON_SCROLL
        };
    }

    componentDidMount() {
        this.handleGetListSeeker('A', 'F', '');
    }

    loadMore = e => {
        const { limit } = this.state;
        const bottom = e.target.scrollTop - (limit/NUMBER_SEEKER_SHOW_ON_SCROLL * e.target.clientHeight) > e.target.clientHeight;
         if (bottom) {
            this.setState({ limit: limit + NUMBER_SEEKER_SHOW_ON_SCROLL });
        }
    }

    onCloseModal = () => {
        this.props.onClose();
    }

    onClickFilterTab = (title) => {
        this.setState({ tabFilterActive: title, limit: NUMBER_SEEKER_SHOW_ON_SCROLL });
        this.handleGetListSeeker(title.split('-')[0].trim(), title.split('-')[1].trim(), this.state.searchText);
    }

    handleGetListSeeker = async (alphabet_start, alphabet_end, keyword) => {
        const input = {
            alphabet_start,
            alphabet_end,
            keyword
        };
        await this.props.getListSeeker(input);
    }


    onShowEditForm = (userId, employee_id) => {

        if(employee_id && this.state.isShowEditForm === 0) {
            this.props.showRootSpinner('document.loading');
        }
        this.setState((prevState) => ({isShowEditForm: prevState.isShowEditForm === userId ? 0 : userId}))
    };

    renderItemSeeker(item, index, length) {
        const { isShowEditForm } = this.state;
        const borderItemStyle = index === length - 1 ? '' : 'border-item'
        return (
            <div className={`${borderItemStyle}`} style={{"flexDirection": "row", marginRight: 15}} key={item.employee_id}>
                <div className="itemSeekerContainer">
                    <div className='column-item'>
                        <p className='column column-name'>{item.fullname}</p>
                    </div>
                    <div className='column-item'>
                        <p className='column'>{item.email}</p>
                    </div>
                    <div className='column-item'>
                        <p className='column'>{item.phone_number}</p>
                    </div>
                    <IconButton onClick={() => this.onShowEditForm(item.user_id, item.employee_id)}>
                        <i className="material-icons" style={{color: '#935EA3'}}>
                            {isShowEditForm === item.user_id ? "cancel" : "add_circle"}
                        </i>
                    </IconButton>
                </div>
                { this.state.isShowEditForm === item.user_id  && (
                    <EditSeekerDetails
                        seekerDetail = {this.props.seekerDetail}
                        onShowEditForm={this.onShowEditForm} 
                        key={item.user_id} 
                        item={item}
                        handleOpenSeekerModal={this.props.handleOpenSeekerModal}
                    />
                )}
            </div>
        )
    }


    render() {
        const { isOpen, loading, listSeeker } = this.props;
        const { tabFilterActive, searchText, limit } = this.state;

        return (
            <div
                className='charge-rate-gig-outer-wrapper'
                style={{ display: isOpen ? 'block' : 'none' }}
                onScroll={this.loadMore}
            >
                <SwipeableDrawer
                    anchor={'right'}
                    open={isOpen}
                    onOpen={() => false}
                    onClose={this.onCloseModal}
                >
                    <div className='drawer-wrapper'>
                        <div className='seeker-wrapper'>
                            <div className='modal-header-container'>
                                <p className='seeker-title font-bold bigger-font'>
                                    <IntlMessages id='seekerModal.title' />
                                </p>
                            </div>
                            <FilterAlphabet
                                onClickTab={(title) => this.onClickFilterTab(title)}
                                tabActive={tabFilterActive}
                                titleTabs={['A - F', 'G - K', 'L - P', 'Q - Z']}
                                isFilter
                            />

                            <SearchSeeker/>
                            <Spinner
                                spinnerWidth={70}
                                show={loading}
                            />
                            {listSeeker.slice(0, limit).map((item, index) => this.renderItemSeeker(item, index, listSeeker.length))}
                        </div>
                    </div>
                </SwipeableDrawer>
            </div>
        );
    }
}

const mapStateToProps = ({ seekerReducer }) => ({
    loading: seekerReducer.loading,
    listSeeker: seekerReducer.listSeeker,
    seekerDetail: seekerReducer.seekerDetail,
    loadingDetail: seekerReducer.loadingDetail
});

const mapActionToProps = {
    getListSeeker,
    getSeekerDetail,
    setSearchText,
    showRootSpinner
};

export default injectIntl(connect(mapStateToProps, mapActionToProps)(SeekerModal));
