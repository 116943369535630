import React, { Component, Fragment } from "react";
import {
  MuiThemeProvider,
  createMuiTheme,
  withStyles,
} from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import Button from "@material-ui/core/Button";
import { RctCard, RctCardContent } from "Components/RctCard";
import { Scrollbars } from "react-custom-scrollbars";
import Information from "Assets/img/Information.png";
import Accpet from "Assets/img/Accept.png";
import Decline from "Assets/img/Decline.png";
// import Information from 'Assets/img/appLogoText.png';
import CircularProgress from "@material-ui/core/CircularProgress";
import Tabs from "@material-ui/core/es/Tabs/Tabs";
import Tab from "@material-ui/core/es/Tab/Tab";
import IntlMessages from "Util/IntlMessages";
import className from "classnames";
import moment from "moment";
import OAuth from "OAuth";
import SeekerInfo from "Components/Model/SeekerInfo";
import Warning from "Assets/img/SweetAlert/warning.png";
import StoredData from "Constants/StoredData";
import MyGig from "Constants/MyGig";
import SweetAlert from "react-bootstrap-sweetalert";
import Tooltip from "@material-ui/core/Tooltip";
import DownloadExcel from "Components/JobCard/DownloadExcel";

import UserDefault from "Assets/img/default-user.png";
import GlassIcon from "Assets/license/acohol-licence-yellow.png";
import DrivingIcon from "Assets/license/driving-licence-yellow.png";
import ScooterIcon from "Assets/license/scooter-licence-yellow.png";

import DownloadPdf from "Components/JobCard/DownloadPdf";
import GigIcon from "Assets/img/gig_large.png";
import { pdfCloseViewer } from "Actions";
import { currentPage } from "Helpers/RouterChecker";

import Under18Icon from "Assets/img/image_under_18.png";
import { FormattedMessage } from "react-intl";

const oauth = new OAuth();

const ICON_HEIGHT = 30;
class ListSeekerTabs extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.postedRef = React.createRef();
    this.state = {
      anchorEl: null,
      tabIndex: 0,
      employee: {},
      assignedScheduleId: null,
      currentSeekerPopupId: 0,
      open: false,
      page: 1,
      openIndexTab: 0,
    };
  }

  handleAcceptSeeker = (employee) => {
    const { positionId } = this.props;

    this.setState({ employee });

    this.props.checkOverlaps({
      positionId,
      employee,
      employeeId: employee.employee_id,
      checkFromJobCard: true,
    });
  };

  handleDenyCandidate = (employee) => {
    this.setState({ employee });
    this.props.openDeclinePopUp(employee.employee_id, employee, true);
  };

  handleDenyAssignment = (employee) => {
    this.setState({ employee });
    this.props.openDeclineAssignmentPopUp(employee.employee_id, employee, true);
  };

  handleNoShowSeeker = (
    assignedScheduleId,
    assignedScheduleStatus,
    assignedScheduleStart
  ) => {
    this.setState({ assignedScheduleId });
    this.props.openNoShow();
  };

  openPopoverOptions = (event, employee) =>
    this.setState({
      anchorEl: event.currentTarget,
      employee,
    });

  openSeekerInfoPopup = (event, employee, tab) => {
    this.setState({
      employee,
      currentSeekerPopupId: employee.employee_id,
      open: true,
      openIndexTab: tab,
    });
  };

  closeSeekerInfoPopup = () => {
    const { openIndexTab } = this.state;
    const { currentPositionId, currentIndex } = this.props;
    switch (openIndexTab) {
      case 0:
        this.props.getCandidatesByPosition(currentPositionId);
        break;
      case 1:
        this.props.getAssignmentsByPosition(currentPositionId);
        break;
      case 2:
        this.props.getDeclinedCandidatesByPosition(currentPositionId);
    }
    this.setState({
      open: false,
    });
  };

  getApiIndexTab = async (index, page = 1) => {
    const { currentPositionId, jobSeeker, positionId } = this.props;
    let position_id = jobSeeker ? positionId : currentPositionId;
    switch (index) {
      case 0:
        await this.props.getCandidatesByPosition(position_id, page);
        break;
      case 1:
        await this.props.getAssignmentsByPosition(position_id, page);
        break;
      case 2:
        await this.props.getDeclinedCandidatesByPosition(position_id, page);
        break;
      default:
        break;
    }
  };

  UNSAFE_componentWillUpdate = (nextProps, nextState) => {
    // if (nextState.tabIndex !== this.state.tabIndex) {
    //     this.getApiIndexTab(nextState.tabIndex, 1)
    // }
    if (nextState.page !== this.state.page) {
      this.getApiIndexTab(nextState.tabIndex, nextState.page);
    }
  };

  changePagination = (e, paginationLength) => {
    if (
      e.target.scrollHeight - Math.ceil(e.target.scrollTop) <=
      e.target.clientHeight
    ) {
      let page = this.state.page + 1;
      if (page <= paginationLength) {
        this.setState({ page });
      }
    }
    //this.setState({page})
  };

  closePopoverOptions = () => this.setState({ anchorEl: null });

  handleTabChange = (event, value) => {
    this.setState({ tabIndex: value, page: 1 });
    this.getApiIndexTab(value, 1);
  };

  computeTotalFavourite = (employmentHistory) =>
    employmentHistory.reduce((total, e) => total + e.employer_favourite, 0);

  checkNoShowVisible = (assignedScheduleStatus, assignedScheduleStart) => {
    let noShowVisible =
      assignedScheduleStatus === MyGig.status.ASSIGNED_SCHEDULE_CHECKED_IN ||
      assignedScheduleStatus === MyGig.status.ASSIGNED_SCHEDULE_UNCHECKED;
    const diffStart = moment
      .unix(assignedScheduleStart)
      .diff(moment(), "minutes");
    if (diffStart > 0) noShowVisible = false;
    return noShowVisible;
  };

  renderButtonAction(employee) {
    const { tabIndex } = this.state;
    const { currentIndex } = this.props;
    const { notShowAction } = this.props;
    const employerRoleId = StoredData.get(StoredData.KEYS.EMPLOYER_ROLE_ID);
    const employerGigPremium = StoredData.get(StoredData.KEYS.GIG_PREMIUM);

    let employerShow = true;

    if (
      Number(employerGigPremium) === oauth.isGigPremium() &&
      Number(employerRoleId) === oauth.isEmployer() &&
      oauth.checkCurrentIsStandard()
    ) {
      employerShow = false;
    }

    return (
      <Fragment>
        {!notShowAction && currentIndex === 0 && employerShow && (
          <div className="btn-actions row m-0">
            <div className="col-6 w-100">
              <img
                className={"accept-button m-auto"}
                onClick={(event) => this.handleAcceptSeeker(employee)}
                src={Accpet}
                height={25}
              />
              <h6>Accept</h6>
            </div>
            <div className="col-6">
              <img
                className={"m-auto"}
                onClick={() => this.handleDenyCandidate(employee)}
                src={Decline}
                height={25}
              />
              <h6>Decline</h6>
            </div>
            {/* <i onClick={(event) => this.openSeekerInfoPopup(event, employee)} className="material-icons gig-button">info outline</i>
                            <i onClick={(event) => this.handleAcceptSeeker(event, employee)} className="material-icons gig-button">check_circle</i>
                            <i onClick={() => this.handleDenyCandidate(employee)} className="material-icons gig-button">cancel</i> */}
          </div>
        )}
        {!notShowAction && currentIndex === 1 && employerShow && (
          <div className="btn-actions">
            <img
              className={"accept-button"}
              onClick={() => this.handleDenyAssignment(employee)}
              src={Decline}
              height={25}
            />
          </div>
        )}
      </Fragment>
    );
  }

  renderAssignedSchedule(assignedSchedule, employeeName) {
    const {
      showAlertNoShow,
      closeAllPopUps,
      positionName,
      noShow,
      positionId,
      jobType,
    } = this.props;
    const { assignedScheduleId } = this.state;
    const dateShift = moment
      .unix(assignedSchedule.schedule.start)
      .format("DD MMM");
    const startShift = moment
      .unix(assignedSchedule.schedule.start)
      .format("H:mm ");
    const endShift = moment.unix(assignedSchedule.schedule.end).format("H:mm ");

    const checkedIn =
      assignedSchedule.status === MyGig.status.ASSIGNED_SCHEDULE_CHECKED_IN ||
      assignedSchedule.status === MyGig.status.ASSIGNED_SCHEDULE_CHECKED_OUT;

    // const noShowVisible = this.checkNoShowVisible(
    //     assignedSchedule.status,
    //     assignedSchedule.schedule.start
    // );

    return (
      <Fragment key={assignedSchedule.id}>
        <div className="schedule">
          <div className="show-left">
            <div className="show-shift">
              <div className="show-date bigger-font thinner">{dateShift}</div>
              <div className="show-time smaller-font">
                {startShift}
                {" - "}
                {endShift}
              </div>
            </div>
          </div>
          {(jobType == MyGig.jobType.PENDING ||
            jobType == MyGig.jobType.COMPLETED) && (
            <div className="show-right">
              <div className="btn-action no-show-btn-group">
                {!oauth.checkCurrentIsStandard() && checkedIn && (
                  <button className="btn-checkin" disabled={checkedIn}>
                    Checked In
                  </button>
                )}
                <button
                  className={
                    !assignedSchedule.is_noshow
                      ? "btn-noshow"
                      : "btn-noshow-disabled"
                  }
                  onClick={() =>
                    this.handleNoShowSeeker(
                      assignedSchedule.id,
                      assignedSchedule.status,
                      assignedSchedule.schedule.start
                    )
                  }
                  disabled={assignedSchedule.is_noshow}
                >
                  No show{" "}
                </button>
              </div>
            </div>
          )}
        </div>
        <SweetAlert
          show={showAlertNoShow && assignedSchedule.id === assignedScheduleId}
          title={<IntlMessages id="button.noShow" />}
          showCancel
          custom
          customIcon={Warning}
          confirmBtnCssClass={"btn-orange"}
          cancelBtnCssClass={"btn-black"}
          onConfirm={() => noShow(assignedSchedule.id, positionId)}
          onCancel={closeAllPopUps}
          cancelBtnText={<IntlMessages id="button.cancel" />}
          confirmBtnText={<IntlMessages id="common.button.confirm" />}
        >
          <IntlMessages
            id="jobCard.listSeekerTab.noShowDescription"
            values={{
              employeeName,
              positionName,
              dateShift,
              startShift,
            }}
          />
        </SweetAlert>
      </Fragment>
    );
  }

  handleCheckUnder18(employee) {
    return (
      employee.date_of_birth &&
      moment().diff(employee.date_of_birth, "years", true) < 18
    );
  }

  renderItemSummary(employee, tab) {
    const { anchorEl, tabIndex } = this.state;
    const { currentIndex } = this.props;
    const isOverlaps = employee.overlaps === 1;
    const showGrayBg = isOverlaps && currentIndex !== 1;
    const cancel_status = 205;
    const cancel_re_apply_status = 207;
    return (
      <li
        key={employee.employee_id}
        className={className("list-parent", { "gray-bg": showGrayBg })}
      >
        <div className="list-group-summary">
          <div
            className="d-flex align-items-center w-100"
            style={{ justifyContent: "space-between" }}
          >
            <div className="d-flex align-items-center">
              <div style={{ position: "relative" }}>
                {employee.role_id === 1 && (
                  <img
                    src={GigIcon}
                    style={{
                      position: "absolute",
                      zIndex: 100,
                    }}
                    className="img-fluid gig-icon"
                  />
                )}
                <div className="job-tabs__item--avatar">
                  {employee.profile_thumbnail ? (
                    <img
                      className=""
                      style={{
                        width: "60px",
                        height: "60px",
                        borderRadius: "50%",
                      }}
                      src={
                        employee.profile_thumbnail + "?" + new Date().getTime()
                      }
                      alt={employee.user_fullname}
                    />
                  ) : (
                    <img
                      className=""
                      style={{
                        width: "60px",
                        height: "60px",
                        borderRadius: "50%",
                      }}
                      src={UserDefault}
                      alt={employee.user_fullname}
                    />
                  )}
                </div>
              </div>

              <div className="item-summary ml-10">
                <div className="smaller-font">
                  <div className={"summary-title"}>
                    <div
                      style={{
                        wordBreak: "break-all",
                        maxWidth: 100,
                      }}
                    >
                      {employee.user_fullname ? employee.user_fullname : ""}
                    </div>
                    <img
                      onClick={(event) =>
                        this.openSeekerInfoPopup(event, employee, currentIndex)
                      }
                      src={Information}
                      height={30}
                      widht={30}
                    />
                  </div>
                  <div className={"d-flex job-icon align-items-center"}>
                    <i className="gig-icon icon-jobs smaller bottom no-pointer" />
                    <span className="ml-2">
                      {employee.total_assignments || 0}
                    </span>

                    <i className="gig-icon icon-thumbs-up smaller bottom no-pointer ml-5" />
                    <span className="ml-2">
                      {employee.total_positive_reviews || 0}
                    </span>

                    <i className="gig-icon icon-favourite smaller bottom no-pointer ml-5" />
                    {employee.employer_favourite > 0 && (
                      <div>
                        <i className="gig-icon icon-favourite smaller bottom no-pointer" />
                      </div>
                    )}
                    {this.handleCheckUnder18(employee) && (
                      <img
                        src={Under18Icon}
                        style={{ width: 16, height: 16 }}
                        className="gig-icon smaller bottom no-pointer ml-2"
                      />
                    )}
                  </div>
                </div>
              </div>

              {(employee.job_response_status == cancel_status ||
                employee.job_response_status == cancel_re_apply_status) &&
                tab == "declined" && (
                  <div
                    style={{
                      position: "relative",
                      marginLeft: "2em",
                    }}
                  >
                    <p className="text-black border rounded bg-orange p-1 m-0">
                      Seeker Cancelled
                    </p>
                  </div>
                )}
            </div>
            {this.renderButtonAction(employee)}
          </div>
        </div>

        <div className="assigned-schedule">
          {(employee.assigned_schedule || []).map((assignedSchedule) =>
            this.renderAssignedSchedule(
              assignedSchedule,
              employee.user_fullname
            )
          )}
        </div>
      </li>
    );
  }

  renderPostedSeekers() {
    const { currentSeekerPopupId, employee, open } = this.state;
    const {
      listCandidates,
      skillId,
      loadingPosted,
      paginationLength,
      currentPositionId,
      jobType,
      positionId,
    } = this.props;
    const hasSeeker = !!listCandidates.length;
    return (
      <Fragment>
        {!loadingPosted && !hasSeeker && (
          <div className="tab-circle-wrapper">
            <IntlMessages id="listSeekerTabs.emptyPosted" />
          </div>
        )}
        {hasSeeker && (
          <Fragment>
            <DownloadPdf
              positionId={positionId}
              jobType={jobType}
              listType="Posted"
            />
            <DownloadExcel
              positionId={positionId}
              type={jobType}
              listType="Posted"
            />
            <ul
              className="list-group-flush auto-scroll-y only"
              ref={(ref) => (this.postedRef = ref)}
              onScroll={(event) =>
                this.changePagination(event, paginationLength)
              }
            >
              {listCandidates.map((employee) =>
                this.renderItemSummary(employee, "posted")
              )}

              {/* <SeekerInfo
                            skillId={skillId}
                            employee={employee}
                            currentSeekerPopupId={currentSeekerPopupId}
                            open={open}
                            closeSeekerInfoPopup={this.closeSeekerInfoPopup.bind(
                                this
                            )}
                        /> */}
              {/* <Pagination page={this.state.page} count={paginationLength} onChange={this.changePagination} /> */}
            </ul>
          </Fragment>
        )}
        {loadingPosted && (
          <div className="tab-circle-wrapper">
            <CircularProgress
              className="progress-warning"
              size={40}
              mode="determinate"
              value={40}
              min={0}
              max={50}
            />
          </div>
        )}
      </Fragment>
    );
  }

  renderFilledSeekers() {
    const {
      listAssignments,
      loadingPosted,
      jobType,
      positionId,
      paginationLength,
    } = this.props;
    const hasSeeker = !!listAssignments.length;
    return (
      <Fragment>
        {!loadingPosted && !hasSeeker && (
          <div className="tab-circle-wrapper">
            <IntlMessages id="listSeekerTabs.emptyFilled" />
          </div>
        )}
        {hasSeeker && (
          <Fragment>
            <DownloadPdf
              positionId={positionId}
              jobType={jobType}
              listType="Filled"
            />
            <DownloadExcel
              positionId={positionId}
              type={jobType}
              listType="Filled"
            />
            <div>
              <ul
                className="list-group-flush auto-scroll-y only"
                ref={(ref) => (this.myRef = ref)}
                onScroll={(event) =>
                  this.changePagination(event, paginationLength)
                }
              >
                {listAssignments.map((employee) =>
                  this.renderItemSummary(employee, "filled")
                )}
              </ul>
            </div>
            {/* <Pagination page={this.state.page} count={paginationLength} onChange = {this.changePagination}/> */}
          </Fragment>
        )}
        {loadingPosted && (
          <div className="tab-circle-wrapper">
            <CircularProgress
              className="progress-warning"
              size={40}
              mode="determinate"
              value={40}
              min={0}
              max={50}
            />
          </div>
        )}
      </Fragment>
    );
  }

  renderDeclinedSeekers() {
    const { employee, currentSeekerPopupId, open } = this.state;
    const {
      listDeclinedCandidates,
      skillId,
      loadingPosted,
      jobType,
      positionId,
      paginationLength,
    } = this.props;
    const hasSeeker = !!listDeclinedCandidates.length;

    return (
      <Fragment>
        {!loadingPosted && !hasSeeker && (
          <div className="tab-circle-wrapper">
            <IntlMessages id="listSeekerTabs.emptyDeclined" />
          </div>
        )}

        {hasSeeker && (
          <Fragment>
            <ul
              className="list-group-flush auto-scroll-y only"
              onScroll={(event) =>
                this.changePagination(event, paginationLength)
              }
            >
              {listDeclinedCandidates.map((employee) =>
                this.renderItemSummary(employee, "declined")
              )}
              {/* <SeekerInfo
                                skillId={skillId}
                                employee={employee}
                                currentSeekerPopupId={currentSeekerPopupId}
                                open={open}
                                closeSeekerInfoPopup={this.closeSeekerInfoPopup.bind(
                                    this
                                )}
                            /> */}
              {/* <Pagination page={this.state.page} count={paginationLength} onChange={this.changePagination} /> */}
            </ul>
          </Fragment>
        )}
        {loadingPosted && (
          <div className="tab-circle-wrapper">
            <CircularProgress
              className="progress-warning"
              size={40}
              mode="determinate"
              value={40}
              min={0}
              max={50}
            />
          </div>
        )}
      </Fragment>
    );
  }

  loadingComponent() {
    return (
      <div className="d-flex justify-content-center loader-overlay">
        <CircularProgress />
      </div>
    );
  }

  render() {
    const { tabIndex, employee, currentSeekerPopupId, open } = this.state;
    const {
      skillId,
      jobChanged,
      currentPage,
      currentDeclineId,
      declineCandidate,
      showAlertDecline,
      currentEmployeeId,
      showAlertAccept,
      showAlertOverlaps,
      showAlertExceedStudent,
      showAlertPositionFilled,
      closeAllPopUps,
      declineAssignment,
      chosenEmployee,
      acceptCandidate,
      positionId,
      showAlertDeclineAssignment,
      currentDeclineAssignmentId,
      dateRange,
      loadingSweetAlert,
      currentIndex,
      checkSeekerAcceptToJob,
      warningSeekerStudent,
      warningSeekerIn8h,
      warningSeeker48h,
    } = this.props;
    const themeIndicator = createMuiTheme({
      overrides: {
        MuiTabs: {
          indicator: {
            backgroundColor: "black",
            height: 1,
          },
        },
      },
    });
    const current_index = jobChanged ? 0 : tabIndex;
    return (
      <div className="job-seeker-card smooth-scrollbar">
        <RctCard style={{ marginBottom: "24px" }}>
          <RctCardContent noPadding>
            <div className="job-seeker-card__wrapper">
              <MuiThemeProvider theme={themeIndicator}>
                <Tabs
                  indicatorColor="primary"
                  className="job-tabs"
                  value={current_index}
                  onChange={this.handleTabChange}
                >
                  <Tab
                    label={<IntlMessages id="tab.posted" />}
                    className="job-tabs__tab"
                  />
                  <Tab
                    label={<IntlMessages id="tab.filled" />}
                    className="job-tabs__tab"
                  />
                  <Tab
                    label={<IntlMessages id="tab.declined" />}
                    className="job-tabs__tab"
                  />
                </Tabs>
              </MuiThemeProvider>
              {current_index === 0 && this.renderPostedSeekers()}
              {current_index === 1 && this.renderFilledSeekers()}
              {current_index === 2 && this.renderDeclinedSeekers()}
            </div>

            {/* Alert decline candidate*/}
            <SweetAlert
              show={
                showAlertDecline &&
                chosenEmployee.employee_id === currentDeclineId
              }
              custom
              customIcon={Warning}
              showCancel
              cancelBtnText={<IntlMessages id="button.cancel" />}
              confirmBtnText={<IntlMessages id="btn.ok" />}
              title={<IntlMessages id="button.decline" />}
              onConfirm={() =>
                declineCandidate(
                  [chosenEmployee.job_response_id],
                  chosenEmployee.position_id,
                  currentPage,
                  false,
                  dateRange
                )
              }
              onCancel={closeAllPopUps}
              confirmBtnCssClass={"btn-orange"}
              cancelBtnCssClass={"btn-black"}
            >
              <IntlMessages id="confirmation.declineRequest" />
              {loadingSweetAlert && this.loadingComponent()}
            </SweetAlert>

            {/* Alert decline assignment*/}
            <SweetAlert
              show={
                showAlertDeclineAssignment &&
                chosenEmployee.employee_id === currentDeclineAssignmentId
              }
              custom
              customIcon={Warning}
              showCancel
              cancelBtnText={<IntlMessages id="button.cancel" />}
              confirmBtnText={<IntlMessages id="txt.confirm" />}
              title={<IntlMessages id="button.decline" />}
              onConfirm={() =>
                declineAssignment(
                  [chosenEmployee.id],
                  chosenEmployee.position_id,
                  currentPage,
                  false,
                  dateRange
                )
              }
              onCancel={closeAllPopUps}
              confirmBtnCssClass={"btn-orange"}
              cancelBtnCssClass={"btn-black"}
            >
              <IntlMessages id="confirmation.declineAssignment" />
              {loadingSweetAlert && this.loadingComponent()}
            </SweetAlert>

            <SweetAlert
              show={
                showAlertOverlaps && employee.employee_id === currentEmployeeId
              }
              danger
              cancelBtnText={<IntlMessages id="common.button.no" />}
              confirmBtnText={<IntlMessages id="common.button.yes" />}
              title={
                <IntlMessages id="components.jobCard.ListSeekerTab.isOverlaps.title" />
              }
              onConfirm={closeAllPopUps}
              confirmBtnCssClass={"btn-orange"}
            >
              <IntlMessages id="components.jobCard.ListSeekerTab.isOverlaps.description" />
            </SweetAlert>

            {/* Alert candidate exceed student hours */}
            <SweetAlert
              show={
                showAlertExceedStudent &&
                employee.employee_id === currentEmployeeId
              }
              danger
              cancelBtnText={<IntlMessages id="common.button.no" />}
              confirmBtnText={<IntlMessages id="common.button.yes" />}
              title={
                <IntlMessages id="components.jobCard.ListSeekerTab.studentExceed.title" />
              }
              onConfirm={closeAllPopUps}
              confirmBtnCssClass={"btn-orange"}
            >
              <IntlMessages id="components.jobCard.ListSeekerTab.studentExceed.description" />
            </SweetAlert>

            {/* Alert position filled */}
            <SweetAlert
              show={
                showAlertPositionFilled &&
                employee.employee_id === currentEmployeeId
              }
              danger
              cancelBtnText={<IntlMessages id="common.button.no" />}
              confirmBtnText={<IntlMessages id="common.button.yes" />}
              title={
                <IntlMessages id="components.jobCard.ListSeekerTab.positionFilled.title" />
              }
              onConfirm={closeAllPopUps}
              confirmBtnCssClass={"btn-orange"}
            >
              <IntlMessages id="components.jobCard.ListSeekerTab.positionFilled.description" />
            </SweetAlert>

            {/* Alert accept candidate */}
            <SweetAlert
              show={
                showAlertAccept &&
                chosenEmployee.employee_id === currentEmployeeId
              }
              custom
              customIcon={Warning}
              cancelBtnText={<IntlMessages id="button.cancel" />}
              confirmBtnText={<IntlMessages id="txt.confirm" />}
              title={<IntlMessages id="button.accept" />}
              showCancel
              onConfirm={() => {
                checkSeekerAcceptToJob(
                  chosenEmployee.position_id,
                  currentPage,
                  [
                    {
                      id: chosenEmployee.employee_id,
                      job_response_id: chosenEmployee.job_response_id,
                    },
                  ]
                );
                closeAllPopUps();
              }}
              onCancel={closeAllPopUps}
              confirmBtnCssClass={"btn-orange"}
              cancelBtnCssClass={"btn-black"}
            >
              <IntlMessages id="confirmation.approveRequest" />
              {loadingSweetAlert && this.loadingComponent()}
            </SweetAlert>

            {/* <SweetAlert
                show={this.props.showAlertRestriction48hours}
                danger
                cancelBtnText={<IntlMessages id="common.button.no" />}
                confirmBtnText={<IntlMessages id="common.button.yes" />}
                title={
                  <IntlMessages id="addSeeker.doAdd.restriction48hours.title" />
                }
                onConfirm={closeAllPopUps}
                confirmBtnCssClass={"btn-orange"}
              >
                <IntlMessages id="addSeeker.doAdd.restriction48hours.message" />
            </SweetAlert> */}
            <SweetAlert
              show={
                (showAlertExceedStudent &&
                  employee.employee_id === currentEmployeeId) ||
                warningSeekerStudent
              }
              danger
              cancelBtnText={<IntlMessages id="common.button.no" />}
              confirmBtnText={<IntlMessages id="common.button.yes" />}
              title={
                <IntlMessages id="components.jobCard.ListSeekerTab.studentExceed.title" />
              }
              onConfirm={closeAllPopUps}
              confirmBtnCssClass={"btn-orange"}
            >
              <IntlMessages id="components.jobCard.ListSeekerTab.studentExceed.description" />
            </SweetAlert>

            <SweetAlert
              show={warningSeekerIn8h}
              custom
              customIcon={Warning}
              btnSize="sm"
              showCancel
              cancelBtnText={<IntlMessages id="button.cancel" />}
              title=""
              onConfirm={() => {
                acceptCandidate({
                  positionId: chosenEmployee.position_id,
                  candidateIds: [chosenEmployee.job_response_id],
                  currentPage,
                  isSelectAll: false,
                  dateRange,
                });
                closeAllPopUps();
              }}
              onCancel={closeAllPopUps}
              confirmBtnCssClass={"btn-orange"}
              cancelBtnCssClass={"btn-black"}
            >
              <FormattedMessage id="addSeeker.doAdd.in8h" />
            </SweetAlert>
            <SweetAlert
              show={warningSeeker48h}
              danger
              cancelBtnText={<IntlMessages id="common.button.no" />}
              confirmBtnText={<IntlMessages id="common.button.yes" />}
              title={
                <IntlMessages id="addSeeker.doAdd.restriction48hours.title" />
              }
              onConfirm={closeAllPopUps}
              confirmBtnCssClass={"btn-orange"}
            >
              <IntlMessages id="addSeeker.doAdd.restriction48hours.message" />
            </SweetAlert>

            <SweetAlert
              show={this.props.warningSeekerOverSixDay}
              custom
              customIcon={Warning}
              btnSize="sm"
              showCancel
              cancelBtnText={<IntlMessages id="button.cancel" />}
              title=""
              onConfirm={() => {
                this.props.hideWarningWorkingSixDay();
                acceptCandidate({
                  positionId: chosenEmployee.position_id,
                  candidateIds: [chosenEmployee.job_response_id],
                  currentPage,
                  isSelectAll: false,
                  dateRange,
                });
              }}
              onCancel={() => this.props.hideWarningWorkingSixDay()}
              confirmBtnCssClass={"btn-orange"}
              cancelBtnCssClass={"btn-black"}
            >
              <FormattedMessage id="acceptSeeker.working6Day" />
            </SweetAlert>
          </RctCardContent>
        </RctCard>
        <SeekerInfo
          skillId={skillId}
          // employee={employee}
          currentSeekerPopupId={currentSeekerPopupId}
          open={open}
          closeSeekerInfoPopup={this.closeSeekerInfoPopup}
        />
      </div>
    );
  }
}

export default ListSeekerTabs;
