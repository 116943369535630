import React from 'react';
import IntlMessages from 'Util/IntlMessages';

class Select extends React.PureComponent {

    static defaultProps = {
        label: "",
        type: "text",
        inputClass: "",
        outerClass: "",
        id: "",
        placeholder: "",
    }

    
    render() {
        const { label, type, inputClass, outerClass, id, placeholder, errors, tabindex, value, onChange, listValue, selected } = this.props;
    
        return (
            <div className={`form-group ${outerClass}`}>
                <label className='black' htmlFor={id || TextBox.defaultProps.id}>
                    {label || TextBox.defaultProps.label}
                </label>
                <select 
            
                  className={`form-control ${inputClass}`} 
                  id={id || TextBox.defaultProps.id}
                  onChange={(value) => onChange(value.target.value)}
                  value={value}
                >
                  {listValue.map((item, index) => (
                    selected == item.value ?
                    <option value={item.value} selected key={index.toString()}>{item.label}</option> :
                    <option value={item.value}  key={index.toString()}>{item.label}</option> 
                    
                  ))}
                </select>
                {errors[id] && <span style={{ color: "red" }}>
                    <IntlMessages id={errors[id]} />
                </span>}
            </div>
        );
    }
}

export default Select;