import React, {Component, PureComponent} from 'react';
import {DatePicker, DateTimePicker} from "@material-ui/pickers";
import {MuiThemeProvider} from "@material-ui/core";
import DateRangeIcon from "@material-ui/icons/DateRange";
import DatepickerOrange from "Components/ThemeOptions/CustomMaterial/DatepickerOrange";
import moment from 'moment';

class DateTime extends PureComponent {
    render() {
        return (
            <div>
                <label>{this.props.label}</label>
                <div className="col-md-12" style={{ paddingLeft: 0, paddingRight: 0 }}>
                    <MuiThemeProvider
                        theme={
                            DatepickerOrange
                        }
                    >
                        <DatePicker
                            value={this.props.dateValue}
                            InputProps={{
                                disableUnderline: true,
                            }}
                            onChange={(value) => this.props.onChange(value)}
                            keyboard
                            keyboardIcon={<DateRangeIcon/>}/>
                    </MuiThemeProvider>
                </div>
            </div>
        );
    }
}
export default DateTime;