import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import UploadDocument from './UploadDocument'
import DateTime from './Datetime';
import { FormGroup, Input, Label } from "reactstrap";

import Switch from "Components/Field/Switch";
import homeOfficeData from '../DetailsTab/HomeOffice.json';


import {
    getJobOfEmployee,
    getDocumentOfEmployee,
    showRootSpinner,
    changeDocumentLoading,
    hideRootSpinner,
    updateSeekerDetail,
    deleteEmployeeExperience,
    addHistoryEmployee,
    deleteHistory
} from 'Actions'
import TextField from '@material-ui/core/TextField';
import {MuiThemeProvider} from "@material-ui/core";
import TextBox from 'Components/Field/TextBox';
import Select from 'Components/Field/Select';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import IntlMessages from "Util/IntlMessages";
import Button from "@material-ui/core/Button";
import IconButton from '@material-ui/core/IconButton';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DatepickerOrange from "Components/ThemeOptions/CustomMaterial/DatepickerOrange";
import Alcohol from 'Assets/license/alcohol-licence.png';
import Driving from 'Assets/license/driving-licence.png';
import Scooter from 'Assets/license/scooter-licence.png';
import HGV from 'Assets/license/hgv-licence.png';
import FoodSafety from 'Assets/qualifications/food-safety.png';
import ForkliftDriver from 'Assets/qualifications/forklift-driver.png';
import ManualHandling from 'Assets/qualifications/manual-handling.png';
import DbsCheck from 'Assets/qualifications/dbs-check.png';

import AppConfig from 'Constants/AppConfig';
import moment from 'moment'

import S3 from 'aws-sdk/clients/s3'


import {
    base64MimeType,
    checkStringFormat,
    getTheDate
} from 'Helpers/helpers';
import SweetAlert from "react-bootstrap-sweetalert";
import {RctCardContent} from "Components/RctCard";
import { red } from '@material-ui/core/colors';


class DocumentsTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            status: "",
            employerId: "",
            pagination: 20,
            searchText: "",
            passport: "",
            passport2: "",
            permit: "",
            contract: "",
            contract2: "",
            contract7: "",
            p46: "",
            contractHgv:"",
            signedContract:"",
            qualification1 : "",
            qualification2 : "",
            qualification3 : "",
            dbsCheck : "",
            showDeletePopUp: false,
            showDeleteHistory: false,
            currentIndexHistory: null,
            filename_passport: "",
            filename_passport2: "",
            filename_permit: "",
            filename_contract: "",
            filename_contract2: "",
            filename_contract7: "",
            filename_contractHgv:"",
            filename_signedContract:"",
            filename_p46: "",
            filename_dbsCheck: "",
            filename_qualification1 : "",
            filename_qualification2 : "",
            filename_qualification3 : "",
            changed_passport: false,
            changed_passport2: false,
            changed_permit: false,
            changed_contract: false,
            changed_contract2: false,
            changed_contract7: false,
            changed_contractHgv:false,
            changed_signedContract:false,
            changed_p46: false,
            changed_qualification1 : false,
            changed_qualification2 : false,
            changed_qualification3 : false,
            changed_dbsCheck : false , 
            checkedCreditCard: "",
            employee_id1_expiry: null,
            employee_id2_expiry: null,
            employee_wp_expiry: null,
            employee_wp_doctype: "",
            isVerifyExperience: false,
            company: "",
            position: "",
            errors : {},
            employments: [],
            history: ""
        };

        this.s3 = new S3({
            accessKeyId: AppConfig.S3_ACESS_KEY,
            secretAccessKey: AppConfig.S3_SECRET_KEY,
            region: AppConfig.S3_REGION
        });
    }

    async submitForm() {
        try {
            // this.props.showRootSpinner('document.loading');
            const {
                    qualification1,
                    qualification2,
                    qualification3,
                    passport,
                    passport2,
                changed_qualification1,
                changed_qualification2,
                changed_qualification3,
                changed_dbsCheck,
                    permit, contract, contract2, contract7, contractHgv,dbsCheck, signedContract ,p46, employee_id1_expiry, employee_id2_expiry, employee_wp_expiry, employments, employee_wp_doctype} = this.state;
            const {changed_passport, changed_passport2, changed_permit, changed_contract, changed_contract2, changed_contract7, changed_p46, changed_contractHgv, changed_signedContract} = this.state;
            const {employee_id} = this.props.item;
            const errors = [];

            employments.forEach((item,index) => {
                const formatDate = 'YYYY-MM-DD';
                const start =  moment(item.start_at, formatDate).unix();
                const end =  moment(item.finish_at, formatDate).unix();
                const diff = end - start ;
                
                if(!item.name) {
                    errors[index] = { ...errors[index],
                        name : "experience.company.empty" };
                }
                if(!item.leave) {
                    errors[index] = {...errors[index],leave : "experience.responsibility.empty"};
                }
                if(diff < 0 )
                {
                   errors[index] = ({...errors[index],timeError : "experience.endDateStartDate.greaterThan"});
                }
                if(!item.start_at)
                {
                    errors[index] = ({...errors[index],start_at : "experience.startdate.empty"})
                }
                if(!item.finish_at)
                {
                    errors[index] = ({...errors[index],finish_at : "experience.endate.empty"})
                }
            }); 
            if (passport.length > 6 && changed_passport) {

                await this.handleUploadImage(passport, 'passport')
            }
           

            if (passport2.length > 6 && changed_passport2) {

                await this.handleUploadImage(passport2, 'passport2')
            }

            if (permit.length > 6 && changed_permit) {

                await this.handleUploadImage(permit, 'permit')
            }

            if (contract.length > 6 && changed_contract) {

                await this.handleUploadImage(contract, 'contract')
            }

            if (contract2.length > 6 && changed_contract2) {

                await this.handleUploadImage(contract2, 'contract2')
            }

            if (contract7.length > 6 && changed_contract7) {

                await this.handleUploadImage(contract7, 'contract7')
            }

            if(contractHgv.length > 6 && changed_contractHgv) {
                await this.handleUploadImage(contractHgv, 'contractHgv')
            }

            if(signedContract.length > 6 && changed_signedContract) {
                await this.handleUploadImage(signedContract, 'signedContract')
            }

            if (p46.length > 6 && changed_p46) {

                await this.handleUploadImage(p46, 'p46')
            }


            if (qualification1.length > 6 && changed_qualification1) {
                await this.handleUploadImage(qualification1, 'qualification1')
            }


            if (qualification2.length > 6 && changed_qualification2) {
                await this.handleUploadImage(qualification2, 'qualification2')
            }


            if (qualification3.length > 6 && changed_qualification3) {
                await this.handleUploadImage(qualification3, 'qualification3')
            }
            if (dbsCheck.length > 6 && changed_dbsCheck) {
                await this.handleUploadImage(dbsCheck, 'dbsCheck')
            }
            let data = {};
            if (employee_wp_expiry) {
                data['employee_wp_expiry'] = employee_wp_expiry
            }

            if (employee_id1_expiry) {
                data['employee_id1_expiry'] = employee_id1_expiry
            }

            if (employee_wp_expiry) {
                data['employee_id2_expiry'] = employee_id2_expiry
            }

            if (employee_wp_doctype && employee_wp_doctype.length > 0) {
                data['employee_wp_doctype'] = employee_wp_doctype
            }

            if (employments && employments.length > 0) {
                data['employment'] = employments.filter(item => item)
            }

            if(errors.length > 0 )
            {
                return this.setState({errors: errors});
            }
            else
            {
                this.props.showRootSpinner('document.loading');
                this.props.updateSeekerDetail(data, employee_id);
                this.props.onShowEditForm();
            }
        } 
        catch (e) 
        {
            
            this.props.hideRootSpinner();
        }
    }


    async handleUploadImage(base64, path) {


        const {omni_employee_id} = this.props.item;
        const mineType = base64MimeType(base64);
        let fileType = [];

        if (mineType && mineType.length > 0) {
            const type = mineType.split('/');
            fileType = type.length > 1 ? type[1] : "";
        }

        const name = path + "/employee-" + omni_employee_id + "." + fileType;
        const buf = new Buffer(base64.replace(/^data:(.+)base64,/, ""), 'base64')
        await this.getListObject(path);
        await this.handleUploadS3(name, buf, mineType)
    }

    async handleUploadS3(name, buf, mineType) {

        const params = {
            Bucket: AppConfig.BUCKET,
            Key: name,
            Body: buf,
            ContentType: mineType,
            ContentEncoding: 'base64',
            ACL: 'public-read',
        };

        await this.s3.upload(params).promise();
    }

    async getListObject(path) {

        const {omni_employee_id} = this.props.item;

        const name = "employee-" + omni_employee_id;
        const params = {
            Bucket: AppConfig.BUCKET,
            Prefix: path + "/"
        }
        const result = await this.s3.listObjectsV2(params).promise();
        if (result.Contents && result.Contents.length > 0) {
            const filterFileName = result.Contents.filter(filename => filename.Key.includes(name))
            if (filterFileName.length > 0) {
                for (let i = 0; i < filterFileName.length; i++) {
                    await this.handleDeleteS3File(filterFileName[i].Key, path)
                }

            }
        }

    }


    async handleDeleteS3File(name) {
        const params = {Bucket: AppConfig.BUCKET, Key: name};
        const result = await this.s3.deleteObject(params, (err, data) => {
        });

        return result;
    }


    UploadImage(image, error, filename) {

        if (typeof error === 'undefined') {
            const file = filename;
            const name = "filename_" + filename;
            const changed = "changed_" + filename
            this.setState({
                [file]: image.image,
                [name]: image.fileName,
                [changed]: true
            });
        } else {
            const {errors} = this.state;
            errors.image = error;
            this.setState({
                errors
            });
        }
    }

    UNSAFE_componentWillMount() {

        this.props.showRootSpinner('document.loading');
        const {omni_employee_id} = this.props.item;
        const {employee_id1_expiry, employee_id2_expiry, employee_wp_expiry, employee_wp_doctype} = this.props.seekerDetail.omni_employee
        const {employments, applicant} = this.props.seekerDetail;

        this.setState({
            applicant: applicant,
            employments: employments,
            employee_wp_doctype: employee_wp_doctype,
            employee_id1_expiry: employee_id1_expiry && checkStringFormat(employee_id1_expiry, "YYYY-MM-DD") ? employee_id1_expiry : null,
            employee_id2_expiry: employee_id2_expiry && checkStringFormat(employee_id2_expiry, "YYYY-MM-DD") ? employee_id2_expiry : null,
            employee_wp_expiry: employee_wp_expiry && checkStringFormat(employee_wp_expiry, "YYYY-MM-DD") ? employee_wp_expiry : null,

        });
        this.props.getDocumentOfEmployee(omni_employee_id)
        
    }

    componentDidUpdate() {
        if (this.props.loadImage) {
            this.props.hideRootSpinner();
            this.setState({
                contract: this.props.listDocument.contract,
                contract2: this.props.listDocument.contract2,
                contract7: this.props.listDocument.contract7,
                contractHgv: this.props.listDocument.contractHgv||"",
                signedContract: this.props.listDocument.signedContract||"",
                p46: this.props.listDocument.p46,
                permit: this.props.listDocument.permit,
                passport: this.props.listDocument.passport,
                passport2: this.props.listDocument.passport2,
                qualification1: this.props.listDocument.qualification1,
                qualification2: this.props.listDocument.qualification2,
                qualification3: this.props.listDocument.qualification3,
                dbsCheck: this.props.listDocument.dbsCheck
            })
            this.props.changeDocumentLoading();
        }
    }

    handleChange = event => {
        this.setState({employee_wp_doctype: event.target.value});
    };

    handleAddSeeker() {
        let {employments, applicant} = this.state;
        const newApplicant = {
            applicant_id: applicant.id,
            country: "Glasgow",
            finish_at: null,
            leave: "",
            name: "",
            pos: "",
            position_id: 0,
            start_at: null,
            town: "",
            currentDeleteIndex: 9999,
        }
        employments.push(newApplicant);
        this.setState({
            employments: employments
        })
    }



    handleIsExperience(isVerifyExperience, value, index ) {
        const  { employments } =  this.state;
        employments[index][isVerifyExperience] = "" ; 
        if(value == 1)
        {
            employments[index][isVerifyExperience] = 1 ; 
        }
        else if (value == 0)
        {
            employments[index][isVerifyExperience] = 0 ; 
        }

        this.setState({
            employments: employments
        })
    }

    onTextChange(name, index, value) {

        let {employments} = this.state;
        employments[index][name] = value;
        if (name === 'start_at') {
            employments[index][name] = value.format('YYYY-MM-DD');
        }
        this.setState({
            employments : employments
        });
    }

    onChangeDateTime(name, value) {
        this.setState({
            [name]: value
        })
    }

    handleDeleteWorkExperience(index) {

        this.showPopUpConfirmDelete()

        this.setState({
            currentDeleteIndex: index
        });
    }

    confirmDelete() {
        let {employments, currentDeleteIndex} = this.state;

        if(employments[currentDeleteIndex].id) {
            this.props.deleteEmployeeExperience({
                employment_id: employments[currentDeleteIndex].id
            })
        }
        delete employments[currentDeleteIndex];
        this.setState({
            employments,
            showDeletePopUp: false
        });

    }

    confirmDeleteHistory() {
        const {currentIndexHistory} = this.state;
        if(currentIndexHistory) {
            this.props.showRootSpinner('document.loading');
            this.props.deleteHistory(currentIndexHistory, this.props.item.employee_id);
        }
        this.setState({
            showDeleteHistory:false
        });
    }

    showPopUpConfirmDelete() {
        this.setState({
            showDeletePopUp: true
        });
    }

    fadeOutComponent() {
      return(  <div onClick={() => this.setState({
          showDeletePopUp: false,
          showDeleteHistory: false
      })} className={"fadeOut"} style={{
            backgroundColor: "rgba(0, 0, 0, 0.4)",
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            zIndex: 5000
        }}>
        </div>)
    }

    addHistory() {
        let params = {};
        const { history } = this.state;

        if(history) {
            params['history'] = history;
            params['employee_id'] = this.props.item.employee_id;
            this.props.showRootSpinner()
            this.props.addHistoryEmployee(params);
        }
    }

    handleDeleteHistory(id) {

            this.setState({
                showDeleteHistory: true,
                currentIndexHistory: id
            });
    }

    render() {

        const {skillByType} = this.props;
        const {passport, passport2, permit, contract, contract2, contract7, contractHgv, signedContract ,p46, employments, qualification1 , qualification2, qualification3,dbsCheck, errors } = this.state
        let skills = skillByType && skillByType.length > 0 ? skillByType.map(item =>  ({"value" : item.id, "label" : item.name})) : [];
      
        return (
            <Fragment>
                <div className={'document-tab'}>
                    <div className={'row'}>
                        <div className={'col-md-6'}>
                            <br/>
                            <h1>Passport/ID</h1>
                            <div className={'row'}>
                                <div className={'col-md-6'}>
                                    <UploadDocument idFile={'passport'} fileName={this.state.filename_passport}
                                                    image={passport}
                                                    uploadImage={(value, error, id) => this.UploadImage(value, error, id)}/>
                                    <div style={{width: '16em'}}>
                                        <DateTime
                                            label="Passport expiry day"
                                            onChange={(value) => this.onChangeDateTime('employee_id1_expiry', value)}
                                            dateValue={this.state.employee_id1_expiry}/>
                                    </div>
                                </div>
                                <div className={'col-md-6'}>
                                    <UploadDocument idFile={'passport2'} fileName={this.state.filename_passport2}
                                                    image={passport2}
                                                    uploadImage={(value, error, id) => this.UploadImage(value, error, id)}/>
                                    <div style={{width: '16em'}}>
                                        <DateTime
                                            label="ID expiry day"
                                            onChange={(value) => this.onChangeDateTime('employee_id2_expiry', value)}
                                            dateValue={this.state.employee_id2_expiry}/>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className={'col-md-5'}>
                            <br/>
                            <div className={'row'}>
                                <div className={'col-md-6'}>
                                    <h1>Visa/Residence permit</h1>
                                </div>
                                <div className={'col-md-6'} style={{paddingLeft: "4em"}}>
                                    <h1>Document type:</h1>
                                </div>
                            </div>

                            <div className={'row'}>
                                <div className={'col-md-6'}>
                                    <UploadDocument idFile={'permit'} fileName={this.state.filename_permit}
                                                    image={permit}
                                                    uploadImage={(value, error, id) => this.UploadImage(value, error, id)}/>
                                    <div style={{width: '16em'}}>
                                        <DateTime
                                            label="Permit expiry day"
                                            onChange={(value) => this.onChangeDateTime('employee_wp_expiry', value)}
                                            dateValue={this.state.employee_wp_expiry}/>
                                    </div>
                                </div>
                                <div className={'col-md-6'}>
                                    <div style={{
                                        display: "flex",
                                        "flexDirection": "column",
                                        "justifyContent": "flex-start",
                                        paddingTop: "10%",
                                        paddingLeft: '4em'
                                    }}>

                                        <RadioGroup aria-label="gender" name="gender2"
                                                    value={this.state.employee_wp_doctype}
                                                    onChange={(e) => this.handleChange(e)}>
                                            <FormControlLabel
                                                value="Work Permit / WHM"
                                                control={<Radio color="default"/>}
                                                label="Work Permit / WHM"
                                                labelplacement="start"
                                                className="document-type"
                                            />
                                            <FormControlLabel
                                                value="VISA"
                                                control={<Radio color="default"/>}
                                                label="VISA"
                                                labelplacement="start"
                                                className="document-type"
                                            />
                                            <FormControlLabel
                                                value="Student letter"
                                                control={<Radio color="default"/>}
                                                label="Student letter"
                                                labelplacement="start"
                                                className="document-type"
                                            />
                                            <FormControlLabel
                                                value="IND application registration certificate"
                                                control={<Radio color="default"/>}
                                                label="IND application registration certificate"
                                                labelplacement="start"
                                                className="document-type"
                                            />
                                        </RadioGroup>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{height: "3rem"}}/>

                    <div className={'row'}>
                        <div className={'col-md-9'}>
                            <h1>Licences</h1>
                        </div>
                    </div>
                    <div className={'row'} style={{marginBottom: -15, marginTop: 15}}>
                        <div className={'col-md-3'}><span>Alcohol Licence:</span></div>
                        <div className={'col-md-3'}><span>Driving Licence:</span></div>
                        <div className={'col-md-3'}><span>Scooter Licence:</span></div>
                        <div className={'col-md-3'}><span>HGV Licence:</span></div>
                    </div>
                    <div className={'row'}>
                        <div className={'col-md-3'}>
                            <UploadDocument
                                replaceImage={Alcohol}
                                idFile={'contract'} fileName={this.state.filename_contract} image={contract}
                                            uploadImage={(value, error, id) => this.UploadImage(value, error, id)}/>
                        </div>
                        <div className={'col-md-3'}>
                            <UploadDocument
                                replaceImage={Driving}
                                idFile={'contract2'} fileName={this.state.filename_contract2}
                                            image={contract2}
                                            uploadImage={(value, error, id) => this.UploadImage(value, error, id)}/>
                        </div>
                        <div className={'col-md-3'}>
                            <UploadDocument
                                replaceImage={Scooter}
                                idFile={'contract7'} fileName={this.state.filename_contract7}
                                            image={contract7}
                                            uploadImage={(value, error, id) => this.UploadImage(value, error, id)}/>
                        </div>
                        <div className={'col-md-3'}>
                            <UploadDocument
                                replaceImage={HGV}           
                                idFile={'contractHgv'} fileName={this.state.filename_contractHgv} 
                                            image={contractHgv}
                                            uploadImage={(value, error, id) => this.UploadImage(value, error, id)}/>
                        </div>
                    </div>
                    <div className={'row'}>
                        <div className={'col-md-9'}>
                            <h1>Qualifications</h1>
                        </div>
                        <div className={'col-md-3'}/>
                    </div>

                    <div className={'row'} style={{marginBottom: -15, marginTop: 15}}>
                        <div className={'col-md-3'}><span>Food Safety/Allergens:</span></div>
                        <div className={'col-md-3'}><span>Manual Handling:</span></div>
                        <div className={'col-md-3'}><span>DBS Check:</span></div>
                        <div className={'col-md-3'}><span>Fork Lift Truck:</span></div>
                    </div>

                    <div className={'row'}>
                        <div className={'col-md-3'}>
                            <UploadDocument
                                replaceImage={FoodSafety}
                                idFile={'qualification1'} fileName={this.state.filename_qualification1}
                                image={qualification1}
                                uploadImage={(value, error, id) => this.UploadImage(value, error, id)}/>
                        </div>
                        <div className={'col-md-3'}>
                            <UploadDocument
                                replaceImage={ManualHandling}
                                idFile={'qualification2'} fileName={this.state.filename_qualification2}
                                image={qualification2}
                                uploadImage={(value, error, id) => this.UploadImage(value, error, id)}/>
                        </div>
                        <div className={'col-md-3'}>
                            <UploadDocument
                                replaceImage={DbsCheck}
                                dbsCheck ={true}
                                idFile={'dbsCheck'} fileName={this.state.filename_dbsCheck}
                                image={dbsCheck}
                                uploadImage={(value, error, id) => this.UploadImage(value, error, id)}/>
                        </div>
                        <div className={'col-md-3'}>
                            <UploadDocument
                                replaceImage={ForkliftDriver}
                                idFile={'qualification3'} fileName={this.state.filename_qualification3}
                                image={qualification3}
                                uploadImage={(value, error, id) => this.UploadImage(value, error, id)}/>
                        </div>
                    </div>
                    <div style={{height: "3rem"}}/>
                    <div className={'row'}>
                        <div className={'col-md-9'}>
                            <h1>Others</h1>
                        </div>
                        <div className={'col-md-3'}/>
                    </div>
                    <div className={'row'} style={{marginBottom: -15, marginTop: 15}}>
                        <div className={'col-md-3'}><span>P45/46:</span></div>
                        <div className={'col-md-3'}><span>Signed Contract:</span></div>
                    </div>

                    <div className={'row'}>
                        <div className={'col-md-3'}>
                            <UploadDocument idFile={'p46'} 
                                            fileName={this.state.filename_p46} image={p46}
                                            uploadImage={(value, error, id) => this.UploadImage(value, error, id)}/>
                        </div>
                        <div className={'col-md-3'}>
                            <UploadDocument idFile={'signedContract'} 
                                            fileName={this.state.filename_signedContract} image={signedContract}
                                            uploadImage={(value, error, id) => this.UploadImage(value, error, id)}
                            />
                        </div>
                    </div>
                    <div style={{height: "3rem"}}/>
                    <div className={'row'}>
                        <div className={'col-md-8'}>
                            <h1>History</h1>
                            <div>
                                {this.props.seekerDetail.notes.length > 0 && this.props.seekerDetail.notes.map(note =>
                                    (<div className={"hitory-item"} key={note.id}>
                                        <div>
                                            <h2> {note.fullname} - {getTheDate(note.created_at, 'H:mm Do MMM')}</h2>
                                            <label>{note.content}</label>
                                        </div>
                                        <IconButton onClick={()=> this.handleDeleteHistory(note.id)}>
                                            <i className="white material-icons close_button">close</i>
                                        </IconButton>
                                    </div>)
                                )}
                            </div>
                        </div>
                    </div>
                    <div className={'row'}>
                        <div className={'col-md-8'}>
                            <div style={{height: "2rem"}}/>
                            <label>Add new:</label>
                            <MuiThemeProvider
                                theme={
                                    {
                                        overrides: {
                                            MuiInput: {
                                                underline: {
                                                    "&&&&:hover:before": {
                                                        border: "none"
                                                    },
                                                    '&&&&:before': {
                                                        border: "none"
                                                    },
                                                    '&&&&:after': {
                                                        border: 'none'
                                                    },
                                                    border: "1px solid",
                                                    "border-radius": "10px"

                                                },
                                            }
                                        }
                                    }}>
                                <TextField
                                    multiline
                                    rows="4"
                                    fullWidth
                                    onChange={(event) => {
                                        this.setState({
                                        history: event.target.value
                                    })}}
                                    multiline={true}/>
                            </MuiThemeProvider>
                        </div>
                    </div>

                    <div style={{height: "1rem"}}/>
                    <Button
                        
                        color="primary"
                        className="text-white gig-button capitalize green line-height-1 new-provider-button width-fit-content"
                        onClick={() => this.addHistory()}
                    >
                        <IntlMessages id="employer.saveChanges"/>
                    </Button>
                    <div style={{height: "3rem"}}/>
                    <div className={'row'}>
                        <div className={'col-md-8'}>
                            <h1>Relevant Work Experience</h1>
                            {employments.length > 0 && employments.map((employment, index) =>
                                (
                                    <Fragment key={index}>
                                        <div
                                            style={{"marginTop": "15px"}}
                                            className={'row justifyContent-end'}>
                                            <IconButton onClick={() => this.handleDeleteWorkExperience(index)}>
                                                <i className="white material-icons close_button">close</i>
                                            </IconButton>

                                        </div>
                                        <div className={'row'} style={{marginTop: 20}}>
                                            <div className={'col-md-6'}>
                                                {employment.position_id == 9999 ?
                                                 <TextBox onChange={(value) => this.onTextChange('pos', index, value)}
                                                         tabindex="1" label="Position" type="text" id={`pos${index}`}
                                                         placeholder="Position" value={employment.pos} required/>  :

                                                <Select
                                                    tabindex="7"  errors={errors} label="Skills" id="skills"
                                                    listValue={skills}
                                                    errors={errors}
                                                    selected = {employment.position_id}
                                                    onChange={(value) => this.onTextChange('position_id', index, value)}
                                                />
                                                }
                                                {/* <div style={{fontSize: 12, color: 'red'}}>
                                                    {errors[index] && errors[index].pos ?  <IntlMessages id={errors[index].pos} /> : ""} 
                                                </div> */}
                                                
                                                <DateTime
                                                    label="Start date"
                                                    onChange={(value) => this.onTextChange('start_at', index, value)}
                                                    dateValue={employment.start_at}/>
                                                    <div style={{fontSize: 12, color: 'red'}}>
                                                    {errors[index] && errors[index].start_at ?  <IntlMessages id={errors[index].start_at} /> : ""} 
                                                       
                                                    </div>
                                            </div>
                                            <div className={'col-md-6'}>

                                                <TextBox tabindex="1"
                                                         name = "company"
                                                         onChange={(value) => this.onTextChange('name', index, value)}
                                                         label="Company" type="text" value={employment.name}
                                                         id={`company${index}`} placeholder="Company"/>
                                                       
                                                <div style={{fontSize: 12, color: 'red'}}>
                                                {errors[index] && errors[index].name ?  <IntlMessages id={errors[index].name} /> : ""} 
                                                </div>
                                                <MuiThemeProvider theme={DatepickerOrange}>
                                                    <DateTime
                                                        label="End date"
                                                        onChange={(value) => this.onTextChange('finish_at', index, value)}
                                                        dateValue={employment.finish_at}/>
                                                <div style={{fontSize: 12, color: 'red'}}>
                                                    {errors[index] && errors[index].finish_at ?  <IntlMessages id={errors[index].finish_at} /> : ""} 
                                                
                                                </div>
                                                </MuiThemeProvider>
                                            </div>
                                            <div style={{fontSize: 12, color: 'red'}}>
                                            {errors[index] && errors[index].timeError ?  <IntlMessages id={errors[index].timeError} /> : ""} 
                                              
                                            </div>
                                        </div>
                                        <div className={'row'}>
                                            <div className={'col-md-12'}>
                                                <div style={{height: "2rem"}}/>
                                                <label>Overview of responsibilities</label>
                                                <MuiThemeProvider
                                                    theme={
                                                        {
                                                            overrides: {
                                                                MuiInput: {
                                                                    underline: {
                                                                        "&&&&:hover:before": {
                                                                            border: "none"
                                                                        },
                                                                        '&&&&:before': {
                                                                            border: "none"
                                                                        },
                                                                        '&&&&:after': {
                                                                            border: 'none'
                                                                        },
                                                                        border: "1px solid",
                                                                        "border-radius": "10px"

                                                                    },
                                                                    focused: {
                                                                        border: "none"
                                                                    }
                                                                }
                                                            }
                                                        }}>
                                                    <TextField
                                                        multiline
                                                        rows="4"
                                                        fullWidth
                                                        multiline={true}
                                                        onChange={(event) => this.onTextChange('leave', index, event.target.value)}
                                                        value={employment.leave}/>
                                                    <div style={{fontSize: 12, color: 'red'}}>
                                                    {errors[index] && errors[index].leave ?  <IntlMessages id={errors[index].leave} /> : ""} 
                                                    </div>
                                                </MuiThemeProvider>
                                            </div>
                                            <div className="col-md-12 switch-item" style={{marginTop: 20}} key={index}>
                                                <Switch
                                                    label="Experience" 
                                                    id={index + 1}
                                                    onChange={(value) => this.handleIsExperience('isVerifyExperience',value, index )}
                                                    checked = {employment.isVerifyExperience}
                                                />
                                            </div> 
                                                           
                                        </div>
                                    </Fragment>
                                )
                            )}
                        </div>
                    </div>
                    <div style={{height: "1rem"}}/>
                    <Button
                        
                        size="small"
                        className="text-white gig-button black margin-right-20 line-height-1 new-provider-button width-fit-content"
                        onClick={() => this.handleAddSeeker()}
                    >
                        Add new
                    </Button>
                    <div style={{height: "4rem"}}/>
                    <div className={'col-md-8'}>
                        <div className="row submit-section">
                            <div className="col-md-12">
                                <div className="row flex-row">
                                    <div className="flex-row">
                                        <Button
                                            
                                            color="primary"
                                            className="text-white gig-button capitalize black margin-right-20 line-height-1 new-provider-button width-fit-content"
                                        >
                                            <IntlMessages id="employer.cancelChange"/>
                                        </Button>


                                        <Button
                                            
                                            color="primary"
                                            className="text-white gig-button capitalize green line-height-1 new-provider-button width-fit-content"
                                            onClick={() => this.submitForm()}
                                        >
                                            <IntlMessages id="employer.saveChanges"/>
                                        </Button>
                                    </div>
                                    <Button
                                        
                                        color="primary"
                                        className="text-white gig-button capitalize gray_a9a9a9  line-height-1 new-provider-button width-fit-content"
                                    >
                                        <IntlMessages id="employer.blockAccount"/>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <SweetAlert
                        hideOverlay
                        show={this.state.showDeletePopUp}
                        danger
                        style={{
                            "display": "block",
                            "position": "fixed",
                            "bottom": "auto",
                            "top": "auto",
                            "marginTop": "-50%",
                            "overflow": "auto"
                        }}
                        showCancel
                        cancelBtnText={<IntlMessages id="common.button.no"/>}
                        confirmBtnText={<IntlMessages id="common.button.yes"/>}
                        title={<IntlMessages id="delete.experience.title"/>}
                        onConfirm={() => this.confirmDelete()}
                        onCancel={() => this.setState({showDeletePopUp: false})}
                        confirmBtnCssClass={"btn-orange"}
                        cancelBtnCssClass={"btn-black"}
                    >
                        <IntlMessages id="delete.experience.description"/>
                    </SweetAlert>

                    <SweetAlert
                        hideOverlay
                        show={this.state.showDeleteHistory}
                        danger
                        style={{
                            "display": "block",
                            "position": "fixed",
                            "bottom": "auto",
                            "top": "auto",
                            "marginTop": "-70%",
                            "overflow": "auto"
                        }}
                        showCancel
                        cancelBtnText={<IntlMessages id="common.button.no"/>}
                        confirmBtnText={<IntlMessages id="common.button.yes"/>}
                        title={<IntlMessages id="delete.history.title"/>}
                        onConfirm={() => this.confirmDeleteHistory()}
                        onCancel={() => this.setState({showDeleteHistory: false})}
                    >
                        <IntlMessages id="delete.history.description"/>
                    </SweetAlert>
                    {this.state.showDeletePopUp|| this.state.showDeleteHistory && this.fadeOutComponent()}

                </div>

            </Fragment>
        )
    }

}


const mapStateToProps = ({documentEmployeeReducer}) => {
    return {
        listDocument: documentEmployeeReducer.listDocument,
        loadImage: documentEmployeeReducer.loadImage,
        skillByType: documentEmployeeReducer.skillByType 
    };
};

const mapActionToProps = {
    getJobOfEmployee,
    getDocumentOfEmployee,
    showRootSpinner,
    hideRootSpinner,
    changeDocumentLoading,
    updateSeekerDetail,
    deleteEmployeeExperience,
    addHistoryEmployee,
    deleteHistory
};

export default connect(mapStateToProps, mapActionToProps)(DocumentsTab);



