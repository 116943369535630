import React, {Component, Fragment} from 'react';
import FilterAlphabet from 'Components/FilterAlphabet/FilterAlphabet';
import DetailsTab from './DetailsTab';
import DocumentsTab from './DocumentsTab';
import JobsTab from  './JobsTab'

class EditSeekerDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
          tabSeekerActive: 'Details'
        };
    }

    onClickSeekerTab = (title) => {
      this.setState({ tabSeekerActive: title })
    }

    render() {
      const { tabSeekerActive } = this.state;
      return (
        <Fragment>
          <br />
          <FilterAlphabet
            onClickTab={(title) => this.onClickSeekerTab(title)}
            tabActive={tabSeekerActive}
            titleTabs={['Details', 'Documents', 'Jobs']}
          />
            {tabSeekerActive === 'Details' && (
                <DetailsTab
                    key={this.props.item.user_id}
                    seekerDetail={this.props.seekerDetail}
                    onShowEditForm={this.props.onShowEditForm}
                    item={this.props.item}
                    handleOpenSeekerModal={this.props.handleOpenSeekerModal}
                />
            )}

            {tabSeekerActive === 'Documents' && (
                <DocumentsTab
                    seekerDetail={this.props.seekerDetail}
                    onShowEditForm={this.props.onShowEditForm}
                    item={this.props.item}
                />
            )}

            {tabSeekerActive === 'Jobs' && (
                <JobsTab
                    onShowEditForm={this.props.onShowEditForm}
                    item={this.props.item}
                />
            )}


        </Fragment>
      );
    }

}

export default EditSeekerDetails;



