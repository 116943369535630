import React, {Component, Fragment} from "react";
import Icon from "@material-ui/core/Icon";
import MyGig from "Constants/MyGig";

class FilterSection extends Component {

    constructor(props) {
        super(props);
    }

    onTextChange(name, value) {
        this.setState({
            [name]: value
        });
    }

    toggleSwitch(name, value) {
        this.setState({
            [name]: value
        });
        this.forceUpdate();

    }


    positionStatus () {
        return [
            {
                name: "Posted",
                status: MyGig.jobType.POSTED
            },
            {
                name: "Filled",
                status: MyGig.jobType.FILLED
            },
            {
                name: "Completed",
                status: MyGig.jobType.COMPLETED
            },
            {
                name: "Pending",
                status: MyGig.jobType.PENDING
            }
        ]

    }


    componentDidMount() {
        const item = this.props.item;
    }

    render() {
        const { employerList, handleFilter } = this.props;
        return (

                <div className="d-flex align-items-end">
                    <div className="form-group col-md-3 col-lg-2">
                        <label>Search jobs:</label>
                        <select className="form-control" name='status' onChange={(e)=>{handleFilter(e.target.value, e.target.name)}}>
                            <option value="" disabled selected hidden>Select status</option>
                            {this.positionStatus().map(status => (
                                <option value={status.status}>{status.name}</option>
                                )

                            )}
                        </select>
                    </div>

                    <div className="form-group col-md-3 col-lg-2">
                        <select className="form-control" name='employerId' onChange={(e)=>{handleFilter(e.target.value, e.target.name)}}>
                            <option value="" disabled selected hidden>Select provider</option>
                            {   employerList.data
                            && employerList.data.data
                            && employerList.data.data.length > 0
                            && employerList.data.data.map(employer => (
                                <option value={employer.id}> {employer.name}</option>
                            ))}
                        </select>
                    </div>

                    <div className="form-group col-md-4 col-lg-3">
                        <div className="input-group">
                            <div className="input-group-prepend">
                                    <span className="input-group-text search-term-icon">
                                        <Icon>search</Icon>
                                    </span>
                            </div>
                            <input type="text" name='searchText' className="form-control search-term-input"
                                   placeholder="Seach term"
                                   onKeyPress={(e)=>{handleFilter(e.target.value, e.target.name, e.charCode||e.which)}}
                                   aria-label="Username"/>
                        </div>
                    </div>

                    <div className="form-group col-md-2 col-lg-1">
                        <label>View:</label>
                        <select className="form-control" name='pagination' onChange={(e)=>{handleFilter(e.target.value, e.target.name)}}>
                            <option value={20}>20</option>
                            <option value={30}>30</option>
                            <option value={50}>50</option>
                        </select>
                    </div>

                    <div className="form-group col-md-12 col-lg-4">
                    </div>
                </div>
        )
    }

}

export default FilterSection;
