import React, {Component, PureComponent} from 'react';
import {FormGroup, Input, InputGroup, InputGroupAddon} from "reactstrap";
import {FormattedMessage, injectIntl} from "react-intl";
import {setSearchText} from "Actions/SeekerAction";
import {connect} from "react-redux";

class SearchSeeker extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            tabSeekerActive: 'Details',
            searchText: ""
        };
    }


    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            
            this.props.setSearchText(e.target.value);
        }
    }

    render() {
        const { searchText } = this.state;
        return (
            <FormGroup className='col-sm-3 col-xs-12 search-container'>
                <InputGroup className='input-group'>
                    <InputGroupAddon addonType='prepend' className='icon-search'>
                        <i className='material-icons'>search</i>
                    </InputGroupAddon>

                    <FormattedMessage id='providerModal.placeholders.searchInput'>
                        {(placeholderText) => (
                            <Input
                                value={searchText}
                                placeholder={placeholderText}
                                onChange={(e) => this.setState({searchText: e.target.value})}
                                onKeyDown={(e) => this.handleKeyDown(e)}
                            />
                        )}
                    </FormattedMessage>
                </InputGroup>
            </FormGroup>
        );
    }

}


const mapStateToProps = ({seekerReducer}) => ({
    listSeeker: seekerReducer.listSeeker,
});

const mapActionToProps = {
    setSearchText,

};

export default injectIntl(connect(mapStateToProps, mapActionToProps)(SearchSeeker));



