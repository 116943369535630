import BaseModel from "Model/BaseModel";
import {
    isEmail,
    isValidPostcode,
    matchCharacter,
    matchCompanyNumber,
    maxLength,
    minLength,
    checkEmailExist,
    checkAccessCode,
    validateVATNumber
} from "Helpers/validators";

export default class EmployerModel extends BaseModel {
    static createFromApiResponseData(data) {

        const model = new EmployerModel;
        model.id = data.id;
        model.level = data.level;
        model.created_at = data.created_at;
        model.position = data.position;
        model.contact_number = data.contact_number;
        model.logo_thumbnail = data.logo_thumbnail;
        return model;

    }

    static async validateFields(params, type = 1) {
        const errors = {};
        const {password, fullName, contactNumber, building, city, companyNo, email, accessCode, companyName, rePassword, street, postcode, vatNo, checkedGigPremium} = params;

        if (!fullName) {
            errors.fullName = 'employer.name.empty';
        }

        if (!companyName) {
            errors.companyName = 'businessDetail.name.empty';
        }

        if (!email) {
            errors.email = 'businessDetail.email.empty';
        }


        if (!errors.email && !isEmail(email)) {
            errors.email = 'employer.email.invalid';
        }

        if (!errors.email && type === 1) {
            const response = await checkEmailExist(email);
            if(response.data.data.exists) {
                errors.email='employer.email.exists';
            }
        }

        if (accessCode) {
            const response = await checkAccessCode(accessCode, checkedGigPremium);
            if (!response.data.data.valid) {
                errors.accessCode = 'employer.accessCode.invalid';
            }
        }


        if (!city) {
            errors.city = 'businessDetail.ba_city.empty';
        }

        if (!building) {
            errors.building = 'businessDetail.ba_building.empty';
        }

        if (!street) {
            errors.street = 'businessDetail.ba_street.empty';
        }

        if (!postcode) {
            errors.postcode = 'businessDetail.ba_postcode.empty';
        }
        if (!errors.postcode && minLength(postcode, 11)) {
            errors.postcode = "businessDetail.ba_postcode.max";
        }

        const checkPostCode = await isValidPostcode(postcode);
        if (!errors.postcode && !checkPostCode) {
            errors.postcode = "businessDetail.ba_postcode.invalid";
        }

        if (!vatNo) {
            errors.vatNo = 'businessDetail.vat_number.empty';
        }
        if (!errors.vatNo && !!validateVATNumber(vatNo)) {
            errors.vatNo = validateVATNumber(vatNo);
        }

        if (!companyNo) {
            errors.companyNo = 'businessDetail.company_number.empty';
        }

        if (!errors.companyNo && !matchCompanyNumber(companyNo)) {
            errors.companyNo = "businessDetail.company_number.invalid";
        }

        if (!contactNumber) {
            errors.contactNumber = "businessDetail.contactNumber.empty";
        }
        if (!errors.contactNumber && minLength(contactNumber, 17)) {
            errors.contactNumber = "businessDetail.contactNumber.max";
        }
        if (!errors.contactNumber && maxLength(contactNumber, 6)) {
            errors.contactNumber = "businessDetail.contactNumber.min";
        }
        if (!errors.contactNumber && matchCharacter(contactNumber)) {
            errors.contactNumber = "businessDetail.contactNumber.invalid";
        }


        if (password.trim() === '' && type === 1) {
            errors.password = 'user.password.empty';
        }

        if (rePassword.trim() === '' && type === 1) {
            errors.rePassword = 'user.password.confirmation.empty';
        }

        if (!!password && !errors.password && !errors.rePassword) {
            if (password !== rePassword) {
                errors.password = 'user.password.not.match';
            }
        }
        if (!!password && (!errors.password)) {
            if (maxLength(password, 5)) {
                errors.password = 'user.minimum.password.length';
            }
            if (maxLength(rePassword, 5) && !errors.rePassword) {
                errors.rePassword = 'user.minimum.password.confirmation.length';
            }
        }
        return errors;
    }
}
