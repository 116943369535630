import React, { PureComponent } from 'react';

class Switch extends PureComponent {

    static defaultProps = {
        label: "",
        placeholder: "",
        labelTextCss: "onoffswitchlabel",
        cssSwitchPoint: "onoffswitch-label",
        cssSwitchCheckBox: "onoffswitch-checkbox",
        cssOuterCheckbox: "onoffswitch",
        cssContainerCheckbox: "onoffswitchouter",
        id: "",
        checked: false
    }

    onChange(value) {
        this.props.onChange(value.target.checked);
    }

    render() {
        const { id, label, labelTextCss, cssSwitchPoint, cssOuterCheckbox, cssSwitchCheckBox, cssContainerCheckbox } = this.props;
        return (
            <div className={cssContainerCheckbox || Switch.defaultProps.cssContainerCheckbox}>
                <div className={cssOuterCheckbox || Switch.defaultProps.cssOuterCheckbox}>
                    <input type="checkbox" name="onoffswitch"
                        onChange={(value) => this.onChange(value)}
                        className={cssSwitchCheckBox || Switch.defaultProps.cssSwitchCheckBox}
                        id={id || Switch.defaultProps.id} checked={this.props.checked} />
                    <label 
                        className={cssSwitchPoint || Switch.defaultProps.cssSwitchPoint}
                        htmlFor={id || Switch.defaultProps.id} />

                </div>
                <label className={labelTextCss || Switch.defaultProps.labelTextCss}>{label || Switch.defaultProps.label}</label>
            </div>
        );
    }
}

export default Switch;