import React, {Component, Fragment} from 'react';
import TextBox from "Components/Field/TextBox";
import UploadImage from "Components/Field/UploadImage";
import Switch from "Components/Field/Switch";
import Button from "@material-ui/core/Button";
import EmployerModel from "Model/EmployerModel";
import { updateEmployer, closePopup} from 'Actions';
import { connect } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import IntlMessages from "Util/IntlMessages";

class EditProvider extends Component {

    constructor(props) {
        super(props);
        this.state = {
            addNewProviderModal: false,
            fullName: "",
            contactNumber: "",
            abbreviation: "",
            password: "",
            building: "",
            city: "",
            companyNo: "",
            email: "",
            accessCode: "",
            companyName: "",
            rePassword: "",
            street: "",
            postcode: "",
            vatNo: "",
            errors: {},
            image: "",
            checkedCreditCard: false,
            checkedGigPremium: false,
            checkedBanned: false,
            fileName: "No file chosen",
            checkedEmailVerify: true
        };
    }

    onTextChange(name, value) {

        this.setState({
            [name]: value
        });
    }

    toggleSwitch(name, value) {
        this.setState({
            [name]: value
        });
        this.forceUpdate();

    }

    componentDidMount() {
        const { banned, logo_thumbnail , abbreviation, building,business_name,city,company_no,contactNumber,email,employer_id,fullname,gig_premium,phone,postcode,street,user_id,vat_no,wages_instant_payment } = this.props.item;
        this.setState({
            checkedBanned: banned,
            abbreviation,
            fullName: fullname,
            building,
            companyName: business_name,
            companyNo: company_no,
            vatNo:vat_no,
            contactNumber,
            city,
            postcode,
            street,
            email,
            image: logo_thumbnail ? logo_thumbnail: "",
            fileName: logo_thumbnail ? "" : "No file chosen",
            checkedCreditCard: wages_instant_payment,
            checkedGigPremium:gig_premium,
            errors: {},
        });
    }

    async submitEditEmployer() {

        const { employer_id } = this.props.item;
        const { abbreviation, building, postcode, street,city ,companyNo, checkedCreditCard,checkedGigPremium,checkedEmailVerify, checkedBanned,vatNo , image, fullName, email, password, rePassword, contactNumber, accessCode, companyName} = this.state;
        let errors  = {};

        errors = await EmployerModel.validateFields(this.state, 2);

        if (Object.keys(errors).length > 0) {

            return this.setState({
                errors
            });
        }

        const params = {
            fullname: fullName,
            email,
            password,
            password_confirmation: rePassword,
            contact_number: contactNumber,
            access_code: accessCode,
            name: companyName,
            abbrv_name: abbreviation,
            logo_thumbnail: image,
            ba_building: building,
            ba_postcode: postcode,
            ba_street: street,
            ba_city: city,
            vat_number: vatNo,
            company_number: companyNo,
            wages_instant_payment: checkedCreditCard ? 1 : 0,
            gig_premium: checkedGigPremium? 1 : 0,
            email_verified: checkedEmailVerify? 1: 0,
            employer_id,
            banned: checkedBanned ? 1 : 0
        };
        this.props.updateEmployer(params);
    }

    UploadImage(image, error) {
        if (typeof error === 'undefined') {
            this.setState({
                image: image.image,
                fileName: image.fileName
            });
        } else {
            const { errors } = this.state;
            errors.image = error;
            this.setState({
                errors
            });
        }
    }


    render() {
        const { failure, success, message} = this.props;
        const {checkedCreditCard, password, rePassword ,checkedGigPremium ,fullName,checkedBanned, abbreviation ,building, companyName,companyNo,vatNo,contactNumber, city, postcode, street, email, errors} = this.state;
        return (
            <Fragment>
                <div className="section-edit">
                    <div className="row">
                        <div className="col-md-5 col-lg-4">
                            <TextBox value={email} errors={errors}  onChange={(value) => this.onTextChange('email', value)} tabindex="1" label="Primary email" type="text" id="email"
                            />

                        </div>
                        <div className="col-md-5 col-lg-4">
                            <TextBox value={contactNumber} errors={errors}  onChange={(value) => this.onTextChange('contactNumber', value)} tabindex="2" label="Primary contact no" type="text" id="contactNumber"
                            />

                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-5 col-lg-4">
                            <TextBox tabindex="3" value={password} errors={errors}  onChange={(value) => this.onTextChange('password', value)} label="Update password" type="password" id="password"
                            />

                        </div>
                        <div className="col-md-5 col-lg-4">
                            <TextBox tabindex="4" value={rePassword} errors={errors}  onChange={(value) => this.onTextChange('rePassword', value)} label="&nbsp;&nbsp;" type="password" id="rePassword"
                            />

                        </div>
                    </div>
                </div>


                <div className="section-edit-profile">
                    <p className='profileName'> Profile </p>
                    <div className="row">
                        <div className="col-md-5 col-lg-4">
                            <TextBox outerClass="textbox-height" value={fullName} errors={errors}  onChange={(value) => this.onTextChange('fullName', value)} tabindex="5" label="Full name" type="text" id="fullName"
                            />
                            <TextBox outerClass="textbox-height" value={companyName}  errors={errors} onChange={(value) => this.onTextChange('companyName', value)} tabindex="6" label="Company name" type="text" id="companyName"
                            />

                            <TextBox outerClass="textbox-height" value={abbreviation} errors={errors} onChange={(value) => this.onTextChange('abbreviation', value)} tabindex="7" label="Abbreviation" type="text" id="abbreviation"
                            />


                        </div>
                        <div className="col-md-5 col-lg-4">
                            <UploadImage fileName={this.state.fileName} image={this.state.image}  uploadImage={(value, error) => this.UploadImage(value, error)}/>

                        </div>
                    </div>

                </div>

                <div className="section-edit-profile">
                    <p className='profileName'> Business Address </p>
                    <div className="row">
                        <div className="col-md-5 col-lg-4">
                            <TextBox value={building} errors={errors}  onChange={(value) => this.onTextChange('building', value)} tabindex="8" label="Building" type="text" id="building"
                            />
                            <TextBox value={city} errors={errors}  onChange={(value) => this.onTextChange('city', value)} tabindex="9" label="City" type="text" id="city"
                            />


                        </div>
                        <div className="col-md-5 col-lg-4">

                            <TextBox value={street} errors={errors}  onChange={(value) => this.onTextChange('street', value)} tabindex="10" label="Street" type="text" id="street"
                            />
                            <TextBox value={postcode} errors={errors}  onChange={(value) => this.onTextChange('postcode', value)} tabindex="11" label="Postcode" type="text" id="postcode"
                            />

                        </div>
                    </div>

                </div>

                <div className="section-edit-profile">
                    <p className='profileName'> Corporate Detail </p>
                    <div className="row">
                        <div className="col-md-5 col-lg-4">
                            <TextBox value= {companyNo} errors={errors}  onChange={(value) => this.onTextChange('companyNo', value)} tabindex="12" label="Company no:" type="text" id="companyNo"
                            />

                        </div>
                        <div className="col-md-5 col-lg-4">
                            <TextBox   value= {vatNo} errors={errors}  onChange={(value) => this.onTextChange('vatNo', value)} tabindex="13" label="VAT no:" type="text" id="vatNo"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-10 col-lg-8">
                            <div className='flex-row'>
                                <Switch checked={checkedCreditCard} label="Credit card required" id="checkedCreditCard"
                                        onChange={(value) => this.toggleSwitch('checkedCreditCard', value)}/>
                                <Switch checked={checkedGigPremium} label="GIG Premium" id="checkedGigPremium"
                                        onChange={(value) => this.toggleSwitch('checkedGigPremium', value)}/>
                                <Switch checked={checkedBanned} label="Banned" id="checkedBanned"
                                        onChange={(value) => this.toggleSwitch('checkedBanned', value)}/>

                            </div>
                        </div>
                    </div>

                    <div className="row submit_section">
                        <div className="col-sm-12 col-md-10 col-lg-8">

                            <div className="row flex-row">

                                <div className="flex-row">
                                    <Button
                                        
                                        color="primary"
                                        className="text-white gig-button capitalize black margin-left-12 line-height-1 new-provider-button width-fit-content"

                                        onClick={() => this.props.onShowEditForm()}
                                    >
                                          <IntlMessages id="employer.cancelChange"/>
                                    </Button>


                                    <Button
                                        
                                        color="primary"
                                        className="text-white gig-button capitalize green margin-left-12 line-height-1 new-provider-button width-fit-content"
                                        onClick={() => this.submitEditEmployer()}
                                    >
                                        <IntlMessages id="employer.saveChanges"/>
                                    </Button>
                                </div>


                                    <Button
                                        
                                        color="primary"
                                        className="text-white gig-button capitalize gray_a9a9a9 margin-left-12  line-height-1 new-provider-button width-fit-content"
                                        // onClick={() => this.submitCreateEmployer()}
                                    >
                                        <IntlMessages id="employer.blockAccount"/>
                                    </Button>

                            </div>

                        </div>
                    </div>
                </div>
                <SweetAlert
                    error={failure}
                    success={success}
                    show={success || failure}
                    title={ failure ? <IntlMessages id='head.popup.errors.title' /> : <IntlMessages id='button.success' />}
                    btnSize="sm"
                    confirmBtnCssClass={"btn-orange"}
                    cancelBtnCssClass={"btn-black"}
                    onConfirm={() => {this.props.closePopup();}}>
                    {message !== null && (
                        message
                    )}
                </SweetAlert>
            </Fragment>
        );
    }

}

const mapStateToProps = ({ EmployerReducer }) => {
    const { success, failure, message } = EmployerReducer;
    return {
        success,
        failure,
        message
    };
};

const mapActionToProps = {
    updateEmployer,
    closePopup
};

export default connect(mapStateToProps, mapActionToProps)(EditProvider);



