import React, {Component, Fragment} from 'react';
import TextBox from "Components/Field/TextBox";
import {connect} from 'react-redux';
import Select from "Components/Field/Select";
import UploadImage from "Components/Field/UploadImage";
import Switch from "Components/Field/Switch";
import Button from "@material-ui/core/Button";
import EmployerModel from "Model/EmployerModel";
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import {
  resetInitLoading, 
  getSeekerDetail, 
  updateSeekerDetail,
  handleUnblockSeeker , 
  resetUpdatePopup, 
  handleBlockSeeker, 
  showRootSpinner, 
  getJobType,
  getSkillBySkillType} from 'Actions';
import SweetAlert from "react-bootstrap-sweetalert";
import SelectMaterialUi  from '@material-ui/core/Select';
import IntlMessages from "Util/IntlMessages";
import {Modal} from "reactstrap";
import DateTime from '../DocumentsTab/Datetime';
import homeOfficeData from './HomeOffice.json';
import employmentStatusData from './EmploymentStatus.json';
import titleData from './Titile.json';
import marriedStatusData from './MarriedStatus.json';
import nationalityData from './Nationality.json';
import niCategoryData from './NiCategory.json';
import paymentMethodData from './PaymentMethod.json';
import {isEmail, maxLength, minLength, isValidInsuranceNumber, isValidPhoneNumber } from "Helpers/validators";
import S3 from 'aws-sdk/clients/s3'
import {
  base64MimeType,
  checkStringFormat,
  getTheDate
} from 'Helpers/helpers';
import AppConfig from 'Constants/AppConfig';

const theme = createMuiTheme({
  overrides: {
      MuiFormLabel: {
          root: {
              '&$focused': {
                  color: 'black',
              },
          },
      },
      MuiInput: {
          underline: {
              '&:after': {
                  borderBottomColor: 'black',
              },
          }
      }
  },
});

class DetailsTab extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: "",
            fullName: "",
            password: "",
            password_confirmation: "",
            note: '',
            employeeRefNo: '',
            title: '',
            firstName: '',
            lastName: '',
            nickName: '',
            maritialStatus: '',
            nationality: '',
            registrationDate: '',
            dateOfBirth: '',
            trainingDate: '',
            homeOffice: '',

            verifiedStoreAndUser: false,
            verifiedStudent: false,
            verifiedTermCondition: false,
            acceptedAceivingUpdates: false,

            emailAddress: '',
            address1: '',
            address2: '',
            address3: '',
            phone: '',
            mobileNo: '',
            postcode: '',
            smartPhone: false,
            nextOfKin: '',

            fileName: '',
            image: '',
            changed_image: false,
            employmentStatus: '',
            nationalInsuranceNo: '',
            niCategory: '',
            taxCode: '1100L',
            p46: '',
            contract: true,
            applicationForm: true,
            wtd: false,
            paymentMethod: '',
            fullNameBank: '',
            accountHolder: '',
            accountNo: '',
            sortCode: '',
            buildingSocietyNo: '',

            profilepic:'',
            profile_thumbnail: '',
            addNewProviderModal: false,
            checkedCreditCard: false,
            checkedGigPremium: false,
            checkedBanned: false,
            checkedEmailVerify: true,
            errors: {},
            multipleSelect: []
        };
        this.s3 = new S3({
          accessKeyId: process.env.S3_ACESS_KEY,
          secretAccessKey: process.env.S3_SECRET_KEY,
          region: process.env.S3_REGION
      });
    }

    async submitEditEmployer() {
        const {
            email, fullName, note, employeeRefNo, title, firstName, lastName,
            nickName, maritialStatus, nationality, emailAddress, address1,
            address2, address3, verifiedStudent, phone, postcode,
            employmentStatus, nationalInsuranceNo, niCategory, fullNameBank,
            accountHolder, accountNo, sortCode, buildingSocietyNo, verifiedStoreAndUser,
            verifiedTermCondition, acceptedAceivingUpdates, paymentMethod,nextOfKin,
            password, password_confirmation, homeOffice,changed_image,multipleSelect,fileName
        } = this.state;

        const errors = {};

        if (!email) {
            errors.email = 'businessDetail.email.empty';
        }

        if (!errors.email && !isEmail(email)) {
            errors.email = 'businessDetail.emai.invalid';
        }

        if (!emailAddress) {
            errors.emailAddress = 'businessDetail.email.empty';
        }

        if (!errors.emailAddress && !isEmail(emailAddress)) {
            errors.emailAddress = 'businessDetail.emai.invalid';
        }



        if (!fullName) {
            errors.fullName = 'loginDetail.name.empty';
        }

        if (!errors.password && !!password && minLength(password,2) && maxLength(password, 5)) {
            errors.currentPassword = 'loginDetail.minimum.currentPassword.length';
        }

        if (!!password && !password_confirmation) {
            errors.password_confirmation = "loginDetail.confirmPassword.empty";
        }

        if (!!password && !errors.password && !errors.password_confirmation) {
            if (password !== password_confirmation) {
                errors.password = 'loginDetail.password.not.match';
            }
        }

        if (!isValidPhoneNumber(phone)) {
            errors.phone = 'phone.invalid';
        }

        if (!isValidInsuranceNumber(nationalInsuranceNo)) {
            errors.nationalInsuranceNo = 'nationalInsuranceNo.invalid'
        }


        if (!title) {
            errors.title = 'title.empty';
        }

        if (!fullNameBank) {
            errors.fullNameBank = "fullNameBank.empty";
        }


        if (!accountHolder) {
            errors.accountHolder = "accountHolder.empty";
        }


        if (!accountNo) {
            errors.accountNo = "accountNo.empty";
        }
        if (isNaN(accountNo)) {
            errors.accountNo = "accountNo.isNaN";
        }
        if (!(accountNo.length <= 8)) {
            errors.accountNo = "accountNo.maxLength"
        }

        if (!firstName) {
            errors.firstName = "firstName.empty";
        }

         if (!lastName) {
            errors.lastName = "lastName.empty";
        }


        if (!sortCode) {
            errors.sortCode = "sortCode.empty";
        }
        if (isNaN(sortCode)) {
            errors.sortCode = "sortCode.isNaN";
        }
        if (!(sortCode.length <= 6)) {
            errors.sortCode = "sortCode.maxLength";
        }

        if (!maritialStatus || maritialStatus === "0" || maritialStatus === "") {
            errors.maritialStatus = "maritialStatus.empty" ;
        }
        if (changed_image)
        {
          await this.handleUploadImage(this.state.image,'avatar');
        }

        if (!nationality || nationality === "0" || nationality === "") {
            errors.nationality = "nationality.empty";
        }
        if (Object.keys(errors).length >0 ) {
            this.setState({
                errors:errors
            })
        } else {
          this.setState({
            errors:[]
          })
            this.props.updateSeekerDetail({
                email,
                fullname: fullName,
                employee_comments: note,
                employee_title: title,
                employee_ref_no: employeeRefNo,
                employee_fname: firstName,
                employee_sname: lastName,
                employee_oname: nickName,
                employee_marital_status: maritialStatus,
                nationality_id: nationality,
                employee_email_address: emailAddress,
                employee_address1: address1,
                employee_address2: address2,
                employee_address3: address3,
                employee_student: verifiedStudent,
                employee_mobile: phone,
                employee_post_code: postcode,
                employee_status: employmentStatus,
                employee_ni: nationalInsuranceNo,
                employee_ni_category: niCategory,
                employee_bank_name: fullNameBank,
                employee_bank_acct_name: accountHolder,
                employee_bank_acct_no: accountNo,
                employee_bank_sort_code: sortCode,
                employee_building_soc_no: buildingSocietyNo,
                is_verified_store_and_use: verifiedStoreAndUser,
                is_verified_read_terms: verifiedTermCondition,
                is_accepted_receiving_update: acceptedAceivingUpdates,
                employee_payment_method: paymentMethod,
                employee_address_tmp: nextOfKin,
                profilepic: this.state.image,
                profile_thumbnail: this.state.image,
                preferred_roles: multipleSelect.length > 0 ? multipleSelect.join() : "",
                //profilepic: this.state.image,
                password,
                password_confirmation,
                home_office: homeOffice
            }, this.props.item.employee_id, true)
        }
    }



    onTextChange(name, value) {
        this.setState({
            [name]: value
        });
    }

    handleBlockOrUnblockSeeker(checkedBanned) {
        if(!checkedBanned) {
            this.props.handleBlockSeeker(this.props.item.employee_id)
        } else {
            this.props.showRootSpinner()
            this.props.handleUnblockSeeker(this.props.item.employee_id)

        }
    }

    UNSAFE_componentWillMount() {
        this.props.getJobType();
        this.props.getSeekerDetail(this.props.item.employee_id)
    }


    componentDidUpdate() {
        const {seekerDetail} = this.props;
      
        if (this.props.loadingDetail) {
            if(seekerDetail.omni_employee && seekerDetail.applicant) {
                var seekerSkill =  !!seekerDetail.applicant.preferred_roles ? seekerDetail.applicant.preferred_roles.split(',').map(item => parseInt(item)) : []
                this.setState({
                    checkedBanned: seekerDetail.user.banned,
                    email: seekerDetail.user.email,
                    fullName: seekerDetail.user.fullname,
                    note: seekerDetail.omni_employee.employee_comments,
                    employeeRefNo: seekerDetail.omni_employee.employee_ref_no,
                    title: seekerDetail.omni_employee.employee_title,
                    firstName: seekerDetail.omni_employee.employee_fname,
                    lastName: seekerDetail.omni_employee.employee_sname,
                    nickName: seekerDetail.omni_employee.employee_oname,
                    maritialStatus: seekerDetail.omni_employee.employee_marital_status,
                    nationality: seekerDetail.omni_employee.nationality_id,
                    emailAddress: seekerDetail.omni_employee.employee_email_address,
                    address1: seekerDetail.omni_employee.employee_address1,
                    address2: seekerDetail.omni_employee.employee_address2,
                    address3: seekerDetail.omni_employee.employee_address3,
                    verifiedStudent: seekerDetail.omni_employee.employee_student === 1,
                    phone: seekerDetail.omni_employee.employee_mobile,
                    mobileNo: seekerDetail.omni_employee.employee_mobile,
                    postcode: seekerDetail.omni_employee.employee_post_code,
                    employmentStatus: seekerDetail.omni_employee.employee_status,
                    nationalInsuranceNo: seekerDetail.omni_employee.employee_ni,
                    niCategory: seekerDetail.omni_employee.employee_ni_category,
                    fullNameBank: seekerDetail.omni_employee.employee_bank_name,
                    accountHolder: seekerDetail.omni_employee.employee_bank_acct_name,
                    accountNo: seekerDetail.omni_employee.employee_bank_acct_no,
                    sortCode: seekerDetail.omni_employee.employee_bank_sort_code,
                    buildingSocietyNo: seekerDetail.omni_employee.employee_building_soc_no,
                    verifiedStoreAndUser: seekerDetail.applicant.is_verified_store_and_use === 1,
                    verifiedTermCondition: seekerDetail.applicant.is_verified_read_terms === 1,
                    acceptedAceivingUpdates: seekerDetail.applicant.is_accepted_receiving_update === 1,
                    paymentMethod: seekerDetail.omni_employee.employee_payment_method,
                    nextOfKin: seekerDetail.omni_employee.employee_address_tmp,
                    image: seekerDetail.employee.profile_thumbnail,
                    homeOffice: seekerDetail.applicant.vacancy_location_id,
                    multipleSelect: seekerSkill
                })
            }
           
            if(seekerDetail.applicant.preferred_roles && seekerDetail.applicant.preferred_roles.length > 0) {
              this.props.getSkillBySkillType(seekerDetail.applicant.preferred_roles);
            }else {
              this.props.getSkillBySkillType(0);
            }
            this.props.resetInitLoading();
          }
    }
    async handleUploadImage(base64, path) {
      const {omni_employee_id} = this.props.item;
      const mineType = base64MimeType(base64);
      let fileType = [];

      if (mineType && mineType.length > 0) {
          const type = mineType.split('/');
          fileType = type.length > 1 ? type[1] : "";
      }

      const name = path + "/employee-" + omni_employee_id + "." + fileType;
      const buf = new Buffer(base64.replace(/^data:(.+)base64,/, ""), 'base64')
      await this.getListObject(path);
      await this.handleUploadS3(name, buf, mineType);
  }

  async handleUploadS3(name, buf, mineType) {
      const params = {
          Bucket: AppConfig.BUCKET,
          Key: name,
          Body: buf,
          ContentType: mineType,
          ContentEncoding: 'base64',
          ACL: 'public-read',
      };
    
      await this.s3.upload(params).promise();
      const host  = 'https://'+ AppConfig.BUCKET+'.s3.eu-west-1.amazonaws.com/'+name;
      this.setState({
        image : host ,
      });
  }

  async getListObject(path) {

      const {omni_employee_id} = this.props.item;

      const name = "employee-" + omni_employee_id;
      const params = {
          Bucket: AppConfig.BUCKET,
          Prefix: path + "/"
      }
      const result = await this.s3.listObjectsV2(params).promise();
      if (result.Contents && result.Contents.length > 0) {
          const filterFileName = result.Contents.filter(filename => filename.Key.includes(name))
          if (filterFileName.length > 0) {
              for (let i = 0; i < filterFileName.length; i++) {
                  await this.handleDeleteS3File(filterFileName[i].Key, path)
              }

          }
      }

  }


  async handleDeleteS3File(name) {
      const params = {Bucket: AppConfig.BUCKET, Key: name};
      const result = await this.s3.deleteObject(params, (err, data) => {
      });

      return result;
  }

    UploadImage(image, error) {
        if (typeof error === 'undefined') {
            this.setState({
                image: image.image,
                fileName: image.fileName,
                changed_image: true
            });
        } else {
            const { errors } = this.state;
            errors.image = error;
            this.setState({
                errors
            });
        }
    }

    componentWillUnmount() {
        this.props.resetInitLoading();
    }


    toggleSwitch(name, value) {
        this.setState({
            [name]: value
        });
        this.forceUpdate();

    }

    componentDidMount() {
        const item = this.props.item;
    } 

    handleSelectSkillType(e) {
      this.setState({
        multipleSelect: e.target.value 
      })
    
        this.props.getSkillBySkillType(e.target.value.join(","));
    }

  

    render() {
        const { multipleSelect } = this.state;
        const {failure, success, message} = this.props;
       
        const {
            email, fullName, password, password_confirmation, note, employeeRefNo, title, firstName, lastName, nickName, maritialStatus, nationality, registrationDate, dateOfBirth, trainingDate,
            acceptedAceivingUpdates, verifiedStoreAndUser, verifiedStudent, verifiedTermCondition, errors,
            emailAddress, address1, address2, address3, phone, mobileNo, postcode, smartPhone, nextOfKin, schedules,checkedBanned, homeOffice,
            fileName, image, employmentStatus, nationalInsuranceNo, niCategory, taxCode, p46, contract, applicationForm, wtd, paymentMethod, fullNameBank, accountHolder, accountNo, sortCode, buildingSocietyNo
        } = this.state;
        return (
            <Fragment>
                <div className="row tab-content-container">
                    <div className="col-md-8 details-left">
                        <h1>Account</h1>
                        <div className="row">
                            <div className="col-md-6">
                                <TextBox
                                    value={email} errors={errors} tabindex="1" label="Email" type="text" id="email"
                                    placeholder="Email"
                                    onChange={(value) => this.onTextChange('email', value)}

                                />
                            </div>
                            <div className="col-md-6">
                                <TextBox
                                    value={fullName} errors={errors} tabindex="2" label="Full name" type="text"
                                    id="fullName" placeholder="Full name"
                                    onChange={(value) => this.onTextChange('fullName', value)}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <TextBox
                                    tabindex="3" value={password} errors={errors} label="Password" type="password"
                                    id="password" placeholder="Password"
                                    onChange={(value) => this.onTextChange('password', value)}
                                />
                            </div>
                            <div className="col-md-6">
                                <TextBox
                                    tabindex="4" value={password_confirmation} errors={errors} label="&nbsp;&nbsp;" type="password"
                                    id="password_confirmation" placeholder="Repeat password"
                                    onChange={(value) => this.onTextChange('password_confirmation', value)}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <Select
                                    tabindex="7"  errors={errors} label="Home Office" id="homeOffice"
                                    listValue={homeOfficeData}
                                    errors={errors}
                                    onChange={(value) => this.onTextChange('homeOffice', value)}
                                />
                            </div>
                        </div>
                  <h1>Personal Details</h1>
                  <span>Payroll reference: <b style={{ fontSize: 25 }}>11318</b></span>
                  <div className="row" style={{ marginTop: 15 }}>
                    <div className="col-md-12">
                      <TextBox
                        tabindex="5" value={note} errors={errors} label="Note" type="textarea" id="note" placeholder="Note" rows={4}
                        onChange={(value) => this.onTextChange('note', value)}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <TextBox
                        tabindex="6" value={employeeRefNo} errors={errors} label="Employee ref no" type="text" id="employeeRefNo" placeholder="Employee reference number"
                        disabled={true}
                      />
                    </div>
                    <div className="col-md-6">
                      <Select
                        tabindex="7" value={title} errors={errors} label="Title" id="title"
                        listValue={titleData}
                        errors={errors}
                        onChange={(value) => this.onTextChange('title', value)}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <TextBox
                        tabindex="8" value={firstName} errors={errors} label="First name" type="text" id="firstName" placeholder="First name"
                        onChange={(value) => this.onTextChange('firstName', value)}
                      />
                    </div>
                    <div className="col-md-6">
                      <TextBox
                        tabindex="9" value={lastName} errors={errors} label="Last name" type="text" id="lastName" placeholder="Last name"
                        onChange={(value) => this.onTextChange('lastName', value)}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <TextBox
                        tabindex="10" value={nickName} errors={errors} label="Nick name" type="text" id="nickName" placeholder="Nick name"
                        onChange={(value) => this.onTextChange('nickName', value)}
                      />
                    </div>
                    <div className="col-md-6">
                      <Select
                        id="skillGroup"
                        tabindex="11" value={maritialStatus} errors={errors} label="Maritial status" id="maritialStatus"
                        listValue={marriedStatusData}
                        onChange={(value) => this.onTextChange('maritialStatus', value)}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <Select
                        tabindex="12" value={nationality} errors={errors} label="Nationality" id="nationality"
                        listValue={nationalityData}
                        onChange={(value) => this.onTextChange('nationality', value)}
                      />
                    </div>
                    <div className="col-md-6">
                      <DateTime
                        label="Registration date"
                        onChange={(value) => {}}
                        // dateValue={this.state.employee_id2_expiry}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <DateTime
                        label="Date of Birth"
                        onChange={(value) => {}}
                        // dateValue={this.state.employee_id2_expiry}
                      />
                    </div>
                    <div className="col-md-6">
                      <DateTime
                        label="Training date"
                        onChange={(value) => {}}
                        // dateValue={this.state.employee_id2_expiry}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                    <MuiThemeProvider theme={theme}>
                    <label className='black' htmlFor="skillGroup">
                        Skill Group
                    </label>
                      <SelectMaterialUi 
                      className="MuiSelect-outlined"
                      multiple
                      value={multipleSelect}
                      onChange={(e) => this.handleSelectSkillType(e) }>
                        {this.props.jobTypes.map((jobType) => (
                           <MenuItem value={jobType.id} key={`select-skill-${jobType.id}`}>
                           {jobType.name}
                       </MenuItem>
                        ))}

                      </SelectMaterialUi>
                      </MuiThemeProvider>
                    </div>
                    <div className="col-md-6">
                      <DateTime
                        label="Training date"
                        onChange={(value) => {}}
                        // dateValue={this.state.employee_id2_expiry}
                      />
                    </div>
                  </div>

                  <br />
                  <div className="row padding-bottom">
                    <div className="col-md-6">
                      <h1>Data Protection</h1>
                    </div>
                    <div className="col-md-6">
                      <h1>Student</h1>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 switch-item">
                      <Switch
                        checked={verifiedStoreAndUser} label="Verified store & user" id="verifiedStoreAndUser"
                      />
                    </div>
                    <div className="col-md-6 switch-item">
                      <Switch
                        checked={verifiedStudent} label="Verified student" id="verifiedStudent"
                        onChange={(value) => this.toggleSwitch('verifiedStudent', value)}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 switch-item">
                      <Switch
                        checked={verifiedTermCondition} label="Verified read term & condition" id="verifiedTermCondition"
                      />
                    </div>
                  </div>
                  <div className="row switch-item">
                    <div className="col-md-12">
                      <Switch 
                        checked={acceptedAceivingUpdates} label="Accepted receiving updates" id="acceptedAceivingUpdates"
                      />
                    </div>
                  </div>

                  <h1 className="padding-top">Contact Information</h1>
                  <div className="row">
                    <div className="col-md-6">
                      <TextBox 
                        tabindex="16" value={emailAddress} errors={errors} label="Email address" type="text" id="emailAddress" placeholder="Email address"
                        onChange={(value) => this.onTextChange('emailAddress', value)} 
                      />
                    </div>
                    <div className="col-md-6">
                      <TextBox 
                        tabindex="17" value={address1} errors={errors} label="Address" type="text" id="address1"
                        onChange={(value) => this.onTextChange('address1', value)} 
                      />
                      <TextBox 
                        tabindex="18" value={address2} errors={errors} type="text" id="address2" outerClass="address-mid-input"
                        onChange={(value) => this.onTextChange('address2', value)}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <TextBox 
                        tabindex="20" value={phone} errors={errors} label="Phone" type="text" id="phone" placeholder="Phone number"
                        onChange={(value) => this.onTextChange('phone', value)} 
                      />
                    </div>
                    <div className="col-md-6">
                      <TextBox 
                        tabindex="19" value={address3} errors={errors} label="&nbsp;&nbsp;" type="text" id="address3"
                        onChange={(value) => this.onTextChange('address3', value)} 
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">

                    </div>
                    <div className="col-md-6">
                      <TextBox 
                        tabindex="22" value={postcode} errors={errors} label="Postcode" type="text" id="postcode" placeholder="Postcode number"
                        onChange={(value) => this.onTextChange('postcode', value)} 
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <Switch 
                        checked={smartPhone} label="Smart phone" id="smartPhone"
                        onChange={(value) => this.toggleSwitch('smartPhone', value)}
                      />
                    </div>
                  </div>
                  <div className="row" style={{ marginTop: 15 }}>
                    <div className="col-md-6">
                      <TextBox 
                        tabindex="23" value={nextOfKin} errors={errors} label="Next of kin" type="text" id="nextOfKin" placeholder="Next of kin"
                        onChange={(value) => this.onTextChange('nextOfKin', value)} 
                      />
                    </div>
                  </div>
                  <div className="row submit-section">
                    <div className="col-md-12">
                      <div className="row flex-row">
                        <div className="flex-row">
                          <Button
                            
                            color="primary"
                            className="text-white gig-button capitalize black margin-right-20 line-height-1 new-provider-button width-fit-content"
                            onClick={() => this.props.onShowEditForm()}
                          >
                            <IntlMessages id="employer.cancelChange"/>
                          </Button>


                                        <Button
                                            
                                            color="primary"
                                            className="text-white gig-button capitalize green line-height-1 new-provider-button width-fit-content"
                                            onClick={() => this.submitEditEmployer()}
                                        >
                                            <IntlMessages id="employer.saveChanges"/>
                                        </Button>
                                    </div>
                                    <Button
                                        
                                        color="primary"
                                        className="text-white gig-button capitalize gray_a9a9a9  line-height-1 new-provider-button width-fit-content"
                                         onClick={() => this.handleBlockOrUnblockSeeker(checkedBanned)}
                                    >
                                        {checkedBanned ?
                                        < IntlMessages id="employer.unblockAccount"/>:
                                            < IntlMessages id="employer.blockAccount"/>
                                        }
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>

                <div className="col-md-4 details-right">
                  <h1>Profile Photo</h1>
                  <p>{this.state.fileName}</p>
                
                  <div className="row">
                    <div className="col-md-12">
                      <UploadImage 
                        fileName={this.state.fileName} 
                        image={this.state.image}  
                        uploadImage={(value, error) => this.UploadImage(value, error)}
                      />
                    </div>
                  </div>
                  <h1>Work Details</h1>
                  <div className="row">
                    <div className="col-md-12">
                      <Select 
                        tabindex="24" value={employmentStatus} errors={errors} label="Employment status" id="employmentStatus"
                        listValue={employmentStatusData}
                        onChange={(value) => this.onTextChange('employmentStatus', value)} 
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <TextBox 
                        value={nationalInsuranceNo} errors={errors} tabindex="25" label="National insurance no" type="text" id="nationalInsuranceNo" placeholder="National insurance number"
                        onChange={(value) => this.onTextChange('nationalInsuranceNo', value)} 
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <Select 
                        tabindex="26" value={niCategory} errors={errors} label="NI category" id="niCategory"
                        listValue={niCategoryData}
                        onChange={(value) => this.onTextChange('niCategory', value)} 
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <TextBox 
                        value={taxCode} errors={errors} tabindex="27" label="Tax code" type="text" id="taxCode" placeholder="Tax code"
                        onChange={(value) => this.onTextChange('taxCode', value)} 
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <Select 
                        tabindex="28" value={p46} errors={errors} label="P46" id="p46"
                        listValue={[{"value": "A", "label": "A"}, {
                            "value": "B",
                            "label": "B"
                        }, {"value": "C", "label": "C"}]}
                        onChange={(value) => this.onTextChange('p46', value)} 
                      />
                    </div>
                  </div>
                  <label>Documentation</label>
                  <div className="row">
                    <div className="col-md-12 switch-item">
                      <Switch
                        checked={contract} label="Contract" id="contract"
                        onChange={(value) => this.toggleSwitch('contract', value)}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 switch-item">
                      <Switch
                        checked={applicationForm} label="Application Form" id="applicationForm"
                        onChange={(value) => this.toggleSwitch('applicationForm', value)}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 switch-item">
                      <Switch
                        checked={wtd} label="WTD" id="wtd"
                        onChange={(value) => this.toggleSwitch('wtd', value)}
                      />
                    </div>
                  </div>
                  <h1 className="padding-top">Bank Details</h1>
                  <div className="row">
                    <div className="col-md-12">
                      <Select 
                        tabindex="29" value={paymentMethod} errors={errors} label="Payment method" id="paymentMethod"
                        listValue={paymentMethodData}
                        onChange={(value) => this.onTextChange('paymentMethod', value)} 
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <TextBox 
                        value={fullNameBank} errors={errors} tabindex="30" label="Full name of bank" type="text" id="fullNameBank" placeholder="Full name of bank"
                        onChange={(value) => this.onTextChange('fullNameBank', value)} 
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <TextBox 
                        value={accountHolder} errors={errors} tabindex="31" label="Account holder" type="text" id="accountHolder" placeholder="Account holder"
                        onChange={(value) => this.onTextChange('accountHolder', value)} 
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <TextBox 
                        value={accountNo} errors={errors} tabindex="32" label="Account no" type="text" id="accountNo" placeholder="Account number"
                        onChange={(value) => this.onTextChange('accountNo', value)} 
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <TextBox 
                        value={sortCode} errors={errors} tabindex="33" label="Sort code" type="text" id="sortCode" placeholder="Sort code"
                        onChange={(value) => this.onTextChange('sortCode', value)} 
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <TextBox 
                        value={buildingSocietyNo} errors={errors} tabindex="34" label="Building society no" type="text" id="buildingSocietyNo" placeholder="Building society number"
                        onChange={(value) => this.onTextChange('buildingSocietyNo', value)} 
                      />
                    </div>
                  </div>
                </div>
              </div>
                <SweetAlert
                    success
                    show={this.props.updateSuccess === true }
                    title={<IntlMessages id='users.update.success' />}
                    btnSize="sm"
                    confirmBtnCssClass={"btn-orange"}
                    cancelBtnCssClass={"btn-black"}
                    onConfirm={() => {this.props.handleOpenSeekerModal()
                        this.props.resetUpdatePopup()}}>
                    Update successful
                </SweetAlert>
            </Fragment>
        );
    }

}

const mapActionToProps = {
    resetInitLoading,
    handleUnblockSeeker,
    getSeekerDetail,
    updateSeekerDetail,
    resetUpdatePopup,
    handleBlockSeeker,
    showRootSpinner,
    getJobType,
    getSkillBySkillType
}

const mapStateToProps = ({seekerReducer}) => ({
    seekerDetail: seekerReducer.seekerDetail,
    loadingDetail: seekerReducer.loadingDetail,
    updateSuccess: seekerReducer.updateSuccess,
    jobTypes:      seekerReducer.jobTypes
});


export default connect(mapStateToProps, mapActionToProps)(DetailsTab);



