import React, { Component, Fragment } from "react";


import {
    Modal,
    ModalHeader,
    ModalBody, Form, FormGroup, Label, Input, ModalFooter,

} from 'reactstrap';
import connect from "react-redux/es/connect/connect";
import IconButton from '@material-ui/core/IconButton';

import QRCode from "react-qr-code";

import LoginDetailModel from "Model/LoginDetailModel";
import {
    openQrCode,
    closeQrCode,
    getQrCodeEmployer,
    getQrCodeEmployerSuccess,
    refreshQrCodeEmployer,
} from "Actions";
import IntlMessages from "Util/IntlMessages";
import Button from "@material-ui/core/Button";

class QrCode extends Component {

    constructor(props) {
        super(props);
        this.state = {
            //loginDetailModal: false,
            id: '',
            employerId: '',
            qrCode:''
        };
    }

    toggleAddBussinessDetailModal = () => {
        const { openPopUp } =this.props;

        if (!openPopUp) {
            this.props.openQrCode();
            this.props.getQrCodeEmployer();
        } else {
            this.props.closeQrCode();
        }
      
    }
    refreshQrCode = () => {
        const {data } = this.props ; 
            this.props.refreshQrCodeEmployer();

    }

    render() {
       const {openPopUp} = this.props;
       const data = this.props.data ; 
       // const data = LoginDetailModel.createFromApiResponseData(this.props.data);
       // const { errors } = this.state;

        return (
            <Fragment>
                <IconButton href={null} onClick={() => this.toggleAddBussinessDetailModal()}>
                    <i className="mt-0 mb-5 gig-icon icon-action icon-qr-code"/>
                </IconButton>
                <Modal isOpen = {openPopUp} toggle={this.toggleAddBussinessDetailModal}>
                    <ModalHeader toggle={this.toggleAddBussinessDetailModal}><span
                        className="text-uppercase orange  location-font-size-header">
                       <IntlMessages className="" id="qr.code.employer" />
                       
                    </span>
                    </ModalHeader>
                    <ModalBody>
                        <Form>  
                            <Label for="location-country">{data.created_at &&  data.created_at}</Label>
                            <FormGroup className='center' >
                          
                                {data.qr_code &&
                                    <QRCode value={data.qr_code}/>
                                }
                            </FormGroup>
                        </Form>
                       
                    
                    </ModalBody>
                    <ModalFooter>
                        <Button  className="gig-button red text-white line-height-1"
                         onClick={() => this.refreshQrCode()}>
                            <IntlMessages id="button.refresh.qr" />
                        </Button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        );
    }
}


const mapStateToProps = ({ qrCode }) => qrCode; 
const mapActionToProps = {
    openQrCode,
    closeQrCode,
    getQrCodeEmployer,
    refreshQrCodeEmployer
};
export default connect(mapStateToProps, mapActionToProps )(QrCode);
