/**
 * Add New Location Component
 * Used To Add New Location
 */
import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import TextBox from 'Components/Field/TextBox';
import Switch from 'Components/Field/Switch';
import UploadImage from 'Components/Field/UploadImage';
import SweetAlert from 'react-bootstrap-sweetalert';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';

import {
    ModalBody,
    ModalFooter,
    Form,
} from 'reactstrap';

// intl message
import IntlMessages from 'Util/IntlMessages';

import { createNewEmployer, closePopup } from 'Actions';
import MyGig from 'Constants/MyGig';
import EmployerModel from 'Model/EmployerModel';
import { connect } from "react-redux";
import {withStyles} from "@material-ui/core/styles";

const ColorButton = withStyles(theme => ({
    root: {
        color: '#fff',
        backgroundColor: '#000',
        fontFamily: 'museosans-700',
        fontSize: '12pt',
        '&:hover': {
            backgroundColor: '#5f5f5f',
        },
    },
}))(Button);

class AddNewProvider extends Component {

    constructor(props) {
        super(props);
        this.state = {
            addNewProviderModal: false,
            fullName: "",
            contactNumber: "",
            abbreviation: "",
            password: "",
            building: "",
            city: "",
            companyNo: "",
            email: "",
            accessCode: "",
            companyName: "",
            rePassword: "",
            street: "",
            postcode: "",
            vatNo: "",
            errors: {},
            image: "",
            checkedCreditCard: false,
            checkedGigPremium: false,
            checkedEmailVerify: false,
            fileName: "No file chosen",
        };
        this.inputRefs = {};
    }

    onTextChange(name, value) {
        this.setState({
            [name]: value
        });
    }

    toggleSwitch(name, value) {
        this.setState({
            [name]: value
        });
        this.forceUpdate();

    }

    UploadImage(image, error) {
        if (typeof error === 'undefined') {
            this.setState({
                image: image.image,
                fileName: image.fileName
            });
        } else {
            const { errors } = this.state;
            errors.image = error;
            this.setState({
                errors
            });
        }
    }

    /**
     * Toggle Add New Location Modal
     */
    toggleAddNewProviderModal = () => {
        const { addNewProviderModal } = this.state;
        if (addNewProviderModal) {
            this.setState({
                image: "",
                checkedCreditCard: false,
                checkedGigPremium: false,
                checkedEmailVerify: false,
                fileName: "No file chosen",
                errors: {}
            });
        }
        this.setState({ addNewProviderModal: !addNewProviderModal });
    }

    async submitCreateEmployer() {

        const { abbreviation, building, street,city ,companyNo, checkedCreditCard,checkedGigPremium,checkedEmailVerify,vatNo , image, fullName, email, password, rePassword, contactNumber, accessCode, companyName, postcode} = this.state;
        let errors  = {};

        errors = await EmployerModel.validateFields(this.state);
        if (Object.keys(errors).length > 0) {
            
            return this.setState({
                errors
            });
        }
  
        const params = {
            fullname: fullName,
            email,
            password,
            password_confirmation: rePassword,
            contact_number: contactNumber,
            access_code: accessCode,
            name: companyName,
            abbrv_name: abbreviation,
            logo_thumbnail: image,
            ba_building: building,
            ba_street: street,
            ba_city: city,
            ba_postcode: postcode,
            vat_number: vatNo,
            company_number: companyNo,
            wages_instant_payment: checkedCreditCard ? 1 : 0,
            gig_premium: checkedGigPremium? 1 : 0,
            email_verified: checkedEmailVerify? 1: 0  
        };
        this.props.createNewEmployer(params);
    }

    render() {
        const { checkedCreditCard, checkedGigPremium, checkedEmailVerify, addNewProviderModal, errors } = this.state;
        const { flag , failure, success, message} = this.props;
        const editAble = Number(flag) === Number(MyGig.location.EDIT_FLAG);
        const { image } = this.state;

        return (
            <div className="z-index-modal">
                <ColorButton
                    onClick={this.toggleAddNewProviderModal}>
                    <IntlMessages id="employer.title.addNew" />
                </ColorButton>
                
                <Dialog
                    hideBackdrop={true}
                    fullWidth
                    maxWidth={false}
                    open={addNewProviderModal} onClose={this.toggleAddNewProviderModal}
                    className="new-provider-modal">
                    <div className='modal-wrapper'>
                    <div className="modal-header">
                        <span className="black provider-font-size-header">
                            <IntlMessages id="employer.title.createNewAccount" />
                        </span>
                        <IconButton onClick={this.toggleAddNewProviderModal}>
                            <i className="white material-icons close_button">close</i>
                        </IconButton>
                    </div>
                    <ModalBody>
                        <Form>
                            <div className="row">
                                <div className='col-md-9'>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div className='display-column'>
                                                <TextBox tabindex="1" errors={errors} onChange={(value) => this.onTextChange('fullName', value)} label="Full name" type="text" id="fullName" placeholder="Full name" value={this.state.fullName} />
                                                <TextBox tabindex="3" errors={errors} onChange={(value) => this.onTextChange('contactNumber', value)} label="Primary contact no" type="text" id="contactNumber" placeholder="Primary contact no" value={this.state.contactNumber} />
                                                <TextBox tabindex="6" errors={errors} onChange={(value) => this.onTextChange('companyName', value)} label="Company name" type="text" id="companyName" placeholder="Company name" value={this.state.companyName} />
                                                <TextBox tabindex="7" errors={errors} onChange={(value) => this.onTextChange('password', value)} label="Create password" type="password" id="password" placeholder="Password" value={this.state.password} />
                                                <TextBox tabindex="9" errors={errors} onChange={(value) => this.onTextChange('building', value)} label="Building" type="text" id="building" value={this.state.building} />
                                                <TextBox tabindex="11" errors={errors} onChange={(value) => this.onTextChange('city', value)} label="City" type="text" id="city" value={this.state.city} />
                                                <TextBox tabindex="13" errors={errors} onChange={(value) => this.onTextChange('companyNo', value)} label="Company no" type="text" id="companyNo" value={this.state.companyNo} />

                                            </div>
                                        </div>

                                        <div className='col-md-6'>
                                            <div className='display-column'>
                                                <TextBox tabindex="2" errors={errors} onChange={(value) => this.onTextChange('email', value)} label="Primary contact email" type="email" id="email" placeholder="Primary contact email" value={this.state.email} />
                                                <TextBox tabindex="4" errors={errors} onChange={(value) => this.onTextChange('accessCode', value)} label="Access code" type="text" id="accessCode" placeholder="Access code"  value={this.state.accessCode} />
                                                <TextBox tabindex="6" errors={errors} onChange={(value) => this.onTextChange('abbreviation', value)} label="Abbreviation" type="text" id="abbreviation" placeholder="Abbreviation" value={this.state.abbreviation} />
                                                <TextBox tabindex="8" errors={errors} onChange={(value) => this.onTextChange('rePassword', value)} label="&nbsp;&nbsp;" type="password" id="rePassword" placeholder="Repeat password" value={this.state.rePassword} />
                                                <TextBox tabindex="10" errors={errors} onChange={(value) => this.onTextChange('street', value)} label="Street" type="text" id="street" value={this.state.street} />
                                                <TextBox tabindex="12" errors={errors} onChange={(value) => this.onTextChange('postcode', value)} label="Postcode" type="text" id="postcode" value={this.state.postcode} />
                                                <TextBox tabindex="14" errors={errors} onChange={(value) => this.onTextChange('vatNo', value)} label="VAT no" type="text" id="vatNo" value={this.state.vatNo} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-3'>
                                    <UploadImage fileName={this.state.fileName} image={image} uploadImage={(value, error) => this.UploadImage(value, error)} />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-9">
                                    <div className='flex-row'>
                                        <Switch checked={checkedCreditCard} label="Credit card required" id="creditcardrequired" onChange={(value) => this.toggleSwitch('checkedCreditCard', value)} />
                                        <Switch checked={checkedGigPremium} label="GIG Premium" id="gigpremium" onChange={(value) => this.toggleSwitch('checkedGigPremium', value)} />
                                        <Switch checked={checkedEmailVerify} label="Email verified" id="emailverify" onChange={(value) => this.toggleSwitch('checkedEmailVerify', value)} />

                                    </div>
                                </div>
                            </div>

                            <div className="row" />

                        </Form>
                    </ModalBody>

                    <ModalFooter>

                        <Button
                            
                            color="primary"
                            className="text-white gig-button capitalize green line-height-1 new-provider-button"
                            onClick={() => this.submitCreateEmployer()}
                        >
                            <IntlMessages id="button.createAccount" />
                        </Button>
                    </ModalFooter>
                    </div>
                    
                    <SweetAlert
                        error={failure}
                        success={success}
                        show={success || failure}
                        title={ failure ? <IntlMessages id='head.popup.errors.title' /> : <IntlMessages id='button.success' />}
                        btnSize="sm"
                        confirmBtnCssClass={"btn-orange"}
                        cancelBtnCssClass={"btn-black"}
                        onConfirm={() => {this.props.closePopup(); this.toggleAddNewProviderModal();}}>
                        {message !== null && (
                            message
                        )}
                    </SweetAlert>
                </Dialog>
            </div>
        );
    }
}

// map state to props
const mapStateToProps = ({ EmployerReducer }) => {
    const { success, failure, message } = EmployerReducer;
    return {
        success,
        failure,
        message
    };
};

const mapActionToProps = {
    createNewEmployer,
    closePopup

};

export default connect(mapStateToProps, mapActionToProps)(AddNewProvider);
