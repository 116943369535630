import React, { PureComponent } from 'react';
import IntlMessages from 'Util/IntlMessages';
import PhotoCamera from 'Assets/img/camera.png';

class UploadImage extends PureComponent {

    onChange(e) {

        const value = e.target.files[0];
        const fileName = value.name;
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            if (this.convertBase64toMB(reader.result) < 1) {
                this.props.uploadImage({
                    image: reader.result,
                    fileName
                }, undefined);
            } else {
                this.props.uploadImage(undefined, "businessDetail.max.size.1MB");
            }
        }, false);
        if (value) {
            reader.readAsDataURL(value);
        }
    }

    convertBase64toMB(base64) {
        const stringLength = base64.length - 'data:image/png;base64,'.length;
        const sizeInBytes = 4 * Math.ceil((stringLength / 3)) * 0.5624896334383812;
        const sizeInMB = sizeInBytes / 1000000;
        return sizeInMB;
    }

    render() {
        const { image, fileName } = this.props;
        return (
            <div className='section-take-image'>
                {image === "" ?
                    <label className="take-photo-image">
                        <img
                            src={PhotoCamera}
                            className="camera-icon"
                            alt="photocamera"
                        />
                    </label> :
                    <img
                        src={image}
                        className="picture-business" />
                }
                <input
                    onChange={e => this.onChange(e)}
                    type="file"
                    id="business_logo"
                    name="business_logo"
                    accept="image/*"
                    className="inputfile-take-photo"
                />
                <div style={{ marginLeft: 40}}>
                    <label htmlFor="business_logo"
                        className="choose-file" >
                        <IntlMessages id="employer.chooseFile" />
                    </label>
                    <label className="fileName">
                    <p>{fileName ? fileName : 'No file selected'}</p>
                    </label>
                </div>
            </div>
        );
    }
}
export default UploadImage;