import React, { PureComponent } from 'react';
import IntlMessages from 'Util/IntlMessages';

class TextBox extends PureComponent {

    static defaultProps = {
        label: "",
        type: "text",
        inputClass: "",
        outerClass: "",
        id: "",
        placeholder: "",
        rows: 1
    }


    render() {
        const { label, type, inputClass, outerClass, id, placeholder, errors, tabindex, value, onChange, rows } = this.props;
    
        return (
            <div className={`form-group textbox-height ${outerClass}`}>
                <label className='black' htmlFor={id || TextBox.defaultProps.id}>
                    {label || TextBox.defaultProps.label}
                </label>
                {type === 'textarea' ? (
                    <textarea 
                        className={`form-control ${inputClass}`} 
                        id={id || TextBox.defaultProps.id}
                        value={value || ''} 
                        tabIndex={tabindex} 
                        onChange={(value) => onChange(value.target.value)}
                        rows={rows}
                        aria-describedby="emailHelp" 
                        placeholder={placeholder || TextBox.defaultProps.placeholder}
                    />
                ) : (
                    <input
                        disabled={this.props.disabled}
                        className={`form-control ${inputClass}`} 
                        id={id || TextBox.defaultProps.id}
                        value={value || ''} 
                        tabIndex={tabindex} 
                        onChange={(value) => onChange(value.target.value)}
                        type={type || TextBox.defaultProps.type} 
                        aria-describedby="emailHelp" 
                        placeholder={placeholder || TextBox.defaultProps.placeholder}
                    />
                )}

                {errors && errors[id] && <span style={{ color: "red" }}>
                    <IntlMessages id={errors[id]} />
                </span>}
            </div>
        );
    }
}

export default TextBox;