import React, { Component } from 'react';
import TableSection from './TableSection'
import FilterSection from './FilterSection'
import {connect} from "react-redux";
import { getJobOfEmployee } from 'Actions'


class JobsTab extends Component {

    constructor(props) {
        super(props);
        this.state = {
            status: "",
            employerId: "",
            pagination: 20,
            searchText:""
        };
    }




    handleFilter(value,name, keycode=null) {


        const { status, employerId, pagination, searchText } = this.state;
        this.setState({
            [name]: value
        })

        const param = {
            status: status,
            employerId: employerId,
            pagination: pagination,
            searchText: searchText,
            [name]: value
        }

        if(keycode === 13 ) {
            this.props.getJobOfEmployee(this.props.item.employee_id, param);
        }else if(name !== 'searchText') {
            this.props.getJobOfEmployee(this.props.item.employee_id, param);
        }
    }


    componentDidMount() {
        this.props.getJobOfEmployee(this.props.item.employee_id, {});
    }

    render() {
        const { employerList, listJobOfEmployee } = this.props;

        return (
            <div className={'job-tab'}>
                <FilterSection handleFilter={(value, name, keycode=null)=>this.handleFilter(value,name, keycode)} employerList={employerList}/>
                <TableSection listJobOfEmployee={listJobOfEmployee}/>
            </div>
        )
    }

}


const mapStateToProps = ({ omniEmployerReducer, jobOfEmployeeReducer }) => {
    return {
        employerList: omniEmployerReducer.employerList,
        listJobOfEmployee: jobOfEmployeeReducer.listJobOfEmployee

    };
};

const mapActionToProps = {
    getJobOfEmployee
};

export default connect(mapStateToProps, mapActionToProps)(JobsTab);




