import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import className from 'classnames';
import Button from '@material-ui/core/Button';
import {withStyles} from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import TextBox from 'Components/Field/TextBox';
import UploadImage from 'Components/Field/UploadImage';
import Spinner from 'Components/Model/Spinner';
import MyGig from 'Constants/MyGig';
import IntlMessages from 'Util/IntlMessages';
import {FormattedMessage, FormattedHTMLMessage, injectIntl} from 'react-intl';
import {FormGroup, Input, InputGroup, InputGroupAddon} from 'reactstrap';

import AddNewProvider from 'Components/AddNewProvider';
import FilterAlphabet from '../../FilterAlphabet/FilterAlphabet';
import {getListProvider} from 'Actions';
import KeyCodes from 'Constants/KeyCodes';

import ItemProvider from "Components/Model/Provider/ItemProvider";


const ColorButton = withStyles(theme => ({
    root: {
        color: '#fff',
        backgroundColor: '#000',
        fontFamily: 'museosans-700',
        fontSize: '12pt',
        '&:hover': {
            backgroundColor: '#5f5f5f',
        },
    },
}))(Button);

class ProviderModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabActive: 'A - F',
            searchText: '',
            isShowEditForm: false
        };
    }


    onCloseModal = () => {
        this.props.onClose();
    }

    onClickTab = (title) => {
        this.setState({tabActive: title});
        this.handleGetListProvider(title.split('-')[0].trim(), title.split('-')[1].trim(), this.state.searchText);
    }

    onSearchProvider = (event) => {
        const {searchText, tabActive} = this.state;
        this.handleGetListProvider(tabActive.split('-')[0].trim(), tabActive.split('-')[1].trim(), searchText);
    }

    handleGetListProvider = async (alphabet_start, alphabet_end, keyword) => {
        const input = {
            alphabet_start,
            alphabet_end,
            keyword
        };
        await this.props.getListProvider(input);
    }

    handleKeyDown = (e) => {
        const key = e.which || e.keyCode;

        if (key === KeyCodes.ENTER) {
            this.onSearchProvider(e);
        }
    }

    toggleSwitch(name, value) {
        this.setState({
            [name]: value
        });
        this.forceUpdate();

    }


    onShowEditForm = () => this.setState({isShowEditForm: !this.state.isShowEditForm});

    renderItemProvider(item, index, length) {
        const {isShowEditForm} = this.state;
        const borderItemStyle = index === length - 1 ? '' : 'border-item';
        return (
            <ItemProvider key={item.user_id} item={item} borderItemStyle={borderItemStyle} />
        );
    }

    render() {
        const {isOpen, loading, listProvider} = this.props;
        const {tabActive, searchText} = this.state;
        return (
            <div
                className='charge-rate-gig-outer-wrapper'
                style={{display: isOpen ? 'block' : 'none'}}
            >
                <SwipeableDrawer
                    anchor={'right'}
                    open={isOpen}
                    onOpen={() => false}
                    onClose={this.onCloseModal}
                >
                    <div className='drawer-wrapper'>
                        <div className='provider-wrapper'>
                            <div className='modal-header-container'>
                                <h1 className='font-bold bigger-font provider-title'>
                                    <IntlMessages id='providerModal.title'/>
                                </h1>
                                <AddNewProvider/>
                            </div>
                            <FilterAlphabet
                                onClickTab={(title) => this.onClickTab(title)}
                                tabActive={tabActive}
                                titleTabs={['A - F', 'G - K', 'L - P', 'Q - Z']}
                            />
                            <FormGroup className='col-sm-3 col-xs-12 search-container'>
                                <InputGroup className='input-group'>
                                    <InputGroupAddon addonType='prepend' className='icon-search'>
                                        <i className='material-icons'>search</i>
                                    </InputGroupAddon>

                                    <FormattedMessage id='providerModal.placeholders.searchInput'>
                                        {(placeholderText) => (
                                            <Input
                                                placeholder={placeholderText}
                                                value={searchText}
                                                onBlur={(e) => this.onSearchProvider(e)}
                                                onChange={(e) => this.setState({searchText: e.target.value})}
                                                onKeyDown={this.handleKeyDown}
                                            />
                                        )}
                                    </FormattedMessage>
                                </InputGroup>
                            </FormGroup>
                            <Spinner
                                spinnerWidth={70}
                                show={loading}
                            />
                            {
                                listProvider.map((item, index) => this.renderItemProvider(item, index, listProvider.length))
                            }
                        </div>
                    </div>
                </SwipeableDrawer>
            </div>
        );
    }
}

const mapStateToProps = ({providerReducer}) => ({
    loading: providerReducer.loading,
    listProvider: providerReducer.listProvider
});

const mapActionToProps = {
    getListProvider
};

export default injectIntl(connect(mapStateToProps, mapActionToProps)(ProviderModal));
