import React, { PureComponent } from 'react';
import IntlMessages from 'Util/IntlMessages';
import PhotoCamera from 'Assets/img/camera.png';
import moment from 'moment';

class UploadDocument extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            scheduledate: moment()
        }
    }

    onChange(e,id) {
        const value = e.target.files[0];
        const fileName = value.name;
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            if (this.convertBase64toMB(reader.result) < 4) {
                this.props.uploadImage({
                    image: reader.result,
                    fileName
                }, undefined, id);
            } else {
                this.props.uploadImage(undefined, "businessDetail.max.size.1MB",id );
            }
        }, false);
        if (value) {
            reader.readAsDataURL(value);
        }
    }

    convertBase64toMB(base64) {
        const stringLength = base64.length - 'data:image/png;base64,'.length;
        const sizeInBytes = 4 * Math.ceil((stringLength / 3)) * 0.5624896334383812;
        const sizeInMB = sizeInBytes / 1000000;
        return sizeInMB;
    }

    handleOpenImageNewTab(image) {
        const iframe = "<iframe width='100%' height='100%' src='" + image + "'></iframe>"
        const x = window.open();
        x.document.write(iframe);
        x.document.close();
    }


    render() {
        const { idFile, replaceImage , dbsCheck} = this.props;
        const { image, fileName } = this.props;
        return (
            <div className='section-take-image'>
                {!image ?
                    <label className="take-photo-image">
                        <img
                            src={PhotoCamera}
                            className="camera-icon"
                            alt="photocamera"
                        />
                    </label> :
                    
                    (replaceImage ?
                        <a href="#" onClick={() => this.handleOpenImageNewTab(image)} >
                        <div className="picture-license">
                        {dbsCheck ? <img
                            src={replaceImage}
                            className="camera-icon" width="130em"/> : <img
                            src={replaceImage}
                            className="camera-icon" />}
                        </div>
                        </a>:
                            <a href="#" onClick={() => this.handleOpenImageNewTab(image)}  className={"linkwrap"}>
                                <div className="blocker"></div>
                    <embed scale="tofit"  src={image} className="picture-business" key={fileName}  />
                            </a>
                    )
                }
                <input
                    onChange={e => this.onChange(e, idFile)}
                    type="file"
                    id={`business_logo${idFile}`}
                    name="business_logo"
                    accept="image/*,application/pdf"
                    className="inputfile-take-photo"
                />
                <label htmlFor={`business_logo${idFile}`}
                       className="choose-file" >
                    <IntlMessages id={fileName ? 'employer.deleteFile' : 'employer.chooseFile'} />
                </label>
                <div className={"outer-filename"}>
                    <p>{fileName ? fileName : 'No file selected'}</p>
                </div>
            </div>
        );
    }
}
export default UploadDocument;