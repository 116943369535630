import React from 'react';
import ListItem from '@material-ui/core/ListItem';

export default class FilterAlphabet extends React.PureComponent {

  checkTabActive = (tabActive, title) => tabActive === title;
  
  renderItemTab = (title, isActive) => {
    const { onClickTab, isFilter } = this.props;
    return (
      <ListItem
        className='col-sm-3 col-md-3 col-xl-3 tab-filter'
        key={title}
        onClick={() => onClickTab(title)}
        disabled={!isActive}
      >
        <div className='col-sm-12 col-md-12 col-xl-12 tab-filter' style={{cursor:'pointer'}}>
          <p className='title-filter-tab' style={isFilter && { letterSpacing: 10 }}>{title}</p>
          <div className='borer-bottom' />
        </div>
      </ListItem>
    );
  }

  render() {
    const { tabActive, titleTabs, isFilter } = this.props;

    return (
      <div className='row col-sm-12 col-md-12 col-xl-12 filter-container'>
        {
          titleTabs.map(item => this.renderItemTab(item, this.checkTabActive(tabActive, item)))
        }
      </div>
    );
  }
}