import React, {Component, Fragment} from 'react';
import IconButton from "@material-ui/core/IconButton";
import EditProvider from "Components/Model/Provider/EditProvider";

class ItemProvider extends  Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowEditForm: false
        };
    }
    onShowEditForm = () => this.setState({isShowEditForm: !this.state.isShowEditForm});
    render() {

        const { borderItemStyle, item } = this.props;
        return(
            <div style={{"flexDirection": "row"}}>
                <div className={`itemProviderContainer ${borderItemStyle}`} key={item.employer_id}>
                    <div className='column-item'>
                        <p className='column column-name'>{item.business_name}</p>
                    </div>
                    <div className='column-item'>
                        <p className='column'>{item.fullname}</p>
                    </div>
                    <div className='column-item'>
                        <p className='column'>{item.email}</p>
                    </div>
                    <div className='column-item'>
                        <p className='column'>{item.business_phone}</p>
                    </div>
                    <IconButton onClick={() => this.onShowEditForm()}>
                        <i className="material-icons" style={{color: '#935EA3'}}>
                            {this.state.isShowEditForm ? "cancel" : "add_circle"}
                        </i>
                    </IconButton>

                </div>
                { this.state.isShowEditForm  && <EditProvider onShowEditForm={()=>this.onShowEditForm()} key={item.user_id} item={item} />}


            </div>
        );
    }

}

export default ItemProvider