import React, { Component } from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Scrollbars } from "react-custom-scrollbars";
import SweetAlert from "react-bootstrap-sweetalert";
import { Input, InputGroupAddon } from "reactstrap";
import Warning from "Assets/img/SweetAlert/warning.png";
import SearchIcon from '@material-ui/icons/Search';
import StoredData from "Constants/StoredData";
import { searchSeekersForPositionRequest as searchSeekers } from "Services/employers/JobService";
import { presignedUrl, uploadFileS3Service } from "../../../../services/UploadS3"

import { 
    createDirectMessage, 
    closeConversation, 
    createConversation, 
    deleteConversation,
    closeDeleteConversationSuccess,
    openConversationMessage,
} from "Actions";
import MessageBlock from "./MessageBlock";
import MyGig from "Constants/MyGig";
import AttachFileIcon from '@material-ui/icons/AttachFile';

import {
    ListItem,
    Avatar,
    Modal,
    IconButton,
    CircularProgress,
    Badge,
    Button,
    List
} from '@material-ui/core'

const initialInput = {
    selectedFile: null,
    messageText: "",
}
class DetailConversation extends Component {
    constructor(props) {
        super(props);
        this.searchInput = null
        this.fileInput = null
        this.state = {
            confirmClose: false,
            confirmOpen: false,
            searchText: "",
            searchSeekers: [],
            selectedSeeker: null,
            confirmDelete: false,
            warningMaxSize : false, 
            warningMaxDuration : false,
            ...initialInput,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { messages } = this.props
        const { messages: newMessages, onLoading, isLoading } = nextProps
        if (!onLoading && !isLoading) {
            if (JSON.stringify(messages) !== JSON.stringify(newMessages)) {
                this.setState({
                    messageText: "",
                    selectedFile: null,
                    searchSeekers: [],
                    selectedSeeker: null
                });
            }
        }
    }

    componentDidMount() {
        this.scrollToBottom();
    }

    componentDidUpdate() {
        this.scrollToBottom();
    }

    scrollToBottom = () => {
        if (!this.props.ignoreAutoToBottom) {
            this.dummyMessage.scrollIntoView({ behavior: "instant" });
        }
    };

    handleScrollConversation(e) {
        // Scroll to top of list messages
        if (typeof e.originalTarget !== "undefined") {
            if (e.originalTarget.scrollTop === 0) {
                this.props.onScrollLoadmore();
            }
        }
    }

    toggleConfirmationClose = () => {
        this.setState({ confirmClose: !this.state.confirmClose });
    };
    toggleConfirmationOpen = () => {
        this.setState({ confirmOpen: !this.state.confirmOpen });
    }

    toggleConfirmationDelete = () => {
        this.setState({ confirmDelete: !this.state.confirmDelete });
    };
    toggleWarningMaxSize = () => {
        this.setState({ warningMaxSize: !this.state.warningMaxSize });
    };
    toggleWarningMaxDuration = () => {
        this.setState({ warningMaxDuration: !this.state.warningMaxDuration });
    };
    checkIsMB = (size)=>{
        return (size / (1024*1024)).toFixed(2)
    }

    uploadFile = async (selectedFile, conversationId, sender_id) => {
        const result = {
            name: '',
            file: ''
        }
        const type = selectedFile.name.split('.')
        const typeFile = type.length > 1 ? type[type.length-1] : ''

        const uploadData = {
            key: `messages/${conversationId}-${sender_id}-${Number(new Date())}.${typeFile}`,
            fileContent: selectedFile //file
        }
        const response = await this.props.onUploadFile(uploadData)

        if (response) {
            result.file = response.Location
            result.name = selectedFile.name
        }

        return result
    }

    refreshInput = () => {
        this.fileInput.value = null
        this.setState(initialInput)
    }

    async saveMessage() {
        const { messageText, selectedFile = false } = this.state;
       
        if (messageText.trim() !== "" || selectedFile) {
            const messageData = {
                message: messageText,
                file: "",
                name_file: "",
                type_message: MyGig.MESSAGE_TYPE.NORMAL,
            }
            if (selectedFile) {
                const {data, status } = await presignedUrl({fileName: selectedFile.name})
                if(status != 200){
                    return 
                }
                const postUrl = data.data.post_url
                const gettUrl = data.data.get_url
    
                const response = await uploadFileS3Service({url: postUrl, file: selectedFile })
                messageData.name_file = selectedFile.name
                messageData.type_message = MyGig.MESSAGE_TYPE.FILE
                messageData.file = gettUrl
               
            }
            const { selectedSeeker } = this.state
            const { openConversation, senderId, conversationId } = this.props
            const isNewMessage = openConversation === "new-message"

        

            const conversation = this.props.list.find(item => item.id === conversationId)

            const title = isNewMessage ? StoredData.get(StoredData.KEYS.EMPLOYER_NAME) : conversation.title
            const name = isNewMessage ? StoredData.get(StoredData.KEYS.EMPLOYER_NAME) : conversation.name
            const user_id = isNewMessage ? selectedSeeker.id : conversation.seeker_id
            const conversation_id = !isNewMessage ?  conversationId : false
            const params = {
                ...messageData,
                title,
                name,
                user_id,
                conversation_id
            }

            this.props.createConversation(params)

            return this.refreshInput()
        }
    }

    checkDuration (duration, file)
    {
        if(duration > 90)
        {
            this.toggleWarningMaxDuration()
        }
        else {
            this.setState({ selectedFile: file})
        }
    };
    
    

    onFileChange(event) {
        if (event.target.files[0]) {
            const file = event.target.files[0];
            const typeFile = event.target.files[0].type.split('/');
            const checkSize = this.checkIsMB(event.target.files[0].size);
            const self = this;
            if(typeFile[0] === 'video')
            {
                const check = false;
                const video = document.createElement('video');
                video.preload = 'metadata';
                video.onloadedmetadata = function() {
                    window.URL.revokeObjectURL(video.src);
                    const duration = video.duration;
                    self.checkDuration(duration, file);
                }
                video.src = URL.createObjectURL(file);
            } else {
                
                if(checkSize > 25)
                {
                    this.toggleWarningMaxSize()
                }
                else {
                    this.setState({ selectedFile: event.target.files[0]})
                }
            }
        }
    }

    showDetailMessage(messages, openConversation, checkEmptyList) {
        const { list, conversationId } = this.props
        const conversation = list.find((item) => item.id === conversationId)
        if (openConversation === "new-message" || checkEmptyList) {
            const { searchSeekers, selectedSeeker } = this.state
            const SearchResults = () => searchSeekers.map((seeker) => {
                return (
                    <ListItem
                        onClick={() => {
                            this.setState({
                                selectedSeeker: {
                                    id: seeker.user_id,
                                    name: seeker.fullname
                                },
                                searchSeekers: searchSeekers.filter((item) => item.user_id === seeker.user_id)
                            })
                        }}
                        key={`${seeker.user_id}seeker`}
                        className={`border-0 my-2 rounded d-flex col-12 messages-item ${selectedSeeker ? 'bg-warning' : 'bg-white'}`}
                    >
                        <div className="col-auto p-0 my-1">
                            <Avatar
                                style={{ width: 50, height: 50 }}
                                alt={seeker.fullname}
                                src={seeker.profile_thumbnail + "?" + new Date().getTime()}
                                className="img-fluid rounded-circle mr-15 align-self-center"
                            />
                        </div>
                        <div className="col p-0 my-1">
                            <p className="text-left h3">{seeker.fullname || ''}</p>
                        </div>
                    </ListItem>
                )
            })
            return (
                <div>
                    <div className="input-group bg-white d-flex m-0">
                        <div className="input-group-prepend">
                            <SearchIcon className="align-self-center m-2" />
                        </div>
                        <input
                            type="text"
                            ref={(ref) => this.searchInput = ref}
                            className="form-control form-control-sm"
                            placeholder="Search..."
                            onKeyDown={(text) => {
                                if (text.key === 'Enter') {
                                    this.handleSearch(this.searchInput.value)
                                }
                            }}
                        />
                    </div>
                    <List>
                        {SearchResults()}
                    </List>
                </div>
            )
        }
        return messages.map((message) => (
            <MessageBlock
                isEmployer={
                    message.author_employee_id === null
                }
                key={`message-block-${message.id}`}
                messageObject={message}
                seekerThumbnail={conversation ? conversation.profile_thumbnail : ""}
                seekerName={conversation ? conversation.seeker_name : ""}
            />
        ))
    }

    handleSearch = (searchText) => {
        if (searchText.length > 3) {
            searchSeekers(searchText, true)
                .then((res) => {
                    if (res.data) {
                        const { list: listConversation } = this.props

                        const seekers = res.data.data

                        this.setState({
                            selectedSeeker: null,
                            searchSeekers: seekers
                        })
                    }
                })
        }
    }

    render() {
        const {
            messages,
            onLoading,
            onSaving,
            conversationId,
            block,
            openConversation,
            list,
            isLoading,
            showAlertDeleteConversation,
            closeDeleteConversationSuccess,
            checkEmptyList
        } = this.props

        const {
            selectedFile,
            messageText,
            confirmClose,
            confirmDelete,
            confirmOpen,
            selectedSeeker,
            warningMaxSize,
            warningMaxDuration
        } = this.state
        
        const isOpenNewMessage = openConversation === "new-message" 
        const isOpen = isOpenNewMessage ? selectedSeeker : (block === MyGig.conversation.OPEN_CONVERSATION || block === MyGig.conversation.SEEKER_DELETE_CONVERSATION)
        return (
            <div className="chat-wrapper">
                <div className="chat-main-body">
                    <Scrollbars
                        className="rct-scroll"
                        autoHide
                        onScroll={(e) => this.handleScrollConversation(e)}
                        style={{
                            height: "calc(100vh - 148px)",
                        }}
                    >
                        <div className="chat-body">

                            {this.showDetailMessage(messages, openConversation, checkEmptyList)}
                            {
                                (onLoading || isLoading) && (
                                    <div className="center vertical-space-smaller">
                                        <CircularProgress
                                            className="progress-warning"
                                            size={40}
                                        />
                                    </div>
                                )
                            }
                            <div
                                style={{ float: "left", clear: "both" }}
                                ref={(el) => {
                                    this.dummyMessage = el;
                                }}
                            />
                            {/* {isOpen && ( */}
                                <div className="chat-input-wrapper">
                                    {/* <InputGroup> */}
                                    <FormattedMessage id="messages.inputMessage.placeholder">
                                        {(placeholder) => (
                                            <Input
                                                autoFocus
                                                type="textarea"
                                                placeholder={placeholder}
                                                disabled={onLoading || onSaving}
                                                value={messageText}
                                                onChange={(e) => this.setState({ messageText: e.target.value, })}
                                            />
                                        )}
                                    </FormattedMessage>
                                    <p className="my-2">{selectedFile ? selectedFile.name : ''}</p>
                                    <p className="chat-note-attachment">{'Note: All attachment(s) will only be available for 2 weeks'}</p>

                                    <InputGroupAddon
                                        addonType="append"
                                        className='mt-3 border-0'
                                    >
                                        {
                                            !selectedSeeker && (
                                                <Button
                                                    onClick={ isOpen ?
                                                        this.toggleConfirmationClose : this.toggleConfirmationOpen
                                                    }
                                                    className="close-conversation-button gig-button send-file bg-light"
                                                >
                                                    {isOpen ? <FormattedMessage id="directMessage.button.close" /> : <FormattedMessage id="directMessage.button.open" />}
                                                </Button>
                                            )
                                        }
                                                <Button
                                                    onClick={
                                                        this.toggleConfirmationDelete
                                                    }
                                                    className="ml-2 delete-conversation-button bg-danger"
                                                >
                                                    <FormattedMessage id="directMessage.button.delete" />
                                                </Button>

                                        <button className="mx-2 send-file">
                                            <label className="send-file-label">
                                                <input
                                                    ref={(ref) => this.fileInput = ref}
                                                    onChange={(e) => this.onFileChange(e)}
                                                    className="input-file"
                                                    type="file"
                                                    accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf,video/*"
                                                />
                                                <AttachFileIcon className="text-light icon-attach-file" />
                                            </label>
                                        </button> 

                                        <IconButton
                                            disabled={!isOpen}
                                            className="btn-warning send-message"
                                            onClick={this.saveMessage.bind(this)}
                                        >
                                            <i className="material-icons">
                                                {isOpen ? "send" : "block"}
                                            </i>
                                        </IconButton>
                                    </InputGroupAddon>
                                    {/* </InputGroup> */}
                                </div>
                            {/* )} */}
                        </div>
                    </Scrollbars>
                </div>

                <Modal hideBackdrop open={confirmClose}>
                    <SweetAlert
                        custom
                        customIcon={Warning}
                        show
                        showCancel
                        closeOnClickOutside={false}
                        confirmBtnText={
                            <FormattedMessage id="common.button.yes" />
                        }
                        cancelBtnText={
                            <FormattedMessage id="common.button.no" />
                        }
                        title={<FormattedMessage id="common.button.confirm" />}
                        onConfirm={() => {
                            this.toggleConfirmationClose();
                            this.props.closeConversation(conversationId);
                        }}
                        onCancel={this.toggleConfirmationClose}
                        confirmBtnCssClass={"btn-orange"}
                        cancelBtnCssClass={"btn-black"}
                    >
                        <FormattedMessage id="directMessage.confirmClose" />
                    </SweetAlert>
                </Modal>

                <Modal hideBackdrop open={confirmDelete}>
                    <SweetAlert
                        custom
                        customIcon={Warning}
                        show
                        showCancel
                        closeOnClickOutside={false}
                        confirmBtnText={
                            <FormattedMessage id="common.button.yes" />
                        }
                        cancelBtnText={
                            <FormattedMessage id="common.button.no" />
                        }
                        title={<FormattedMessage id="common.button.confirm" />}
                        onConfirm={() => {
                            this.toggleConfirmationDelete();
                            this.props.deleteConversation(conversationId);
                        }}
                        onCancel={this.toggleConfirmationDelete}
                        confirmBtnCssClass={"btn-orange"}
                        cancelBtnCssClass={"btn-black"}
                    >
                        <FormattedMessage id="directMessage.confirmDelete" />
                    </SweetAlert>
                </Modal>

                <Modal hideBackdrop open={showAlertDeleteConversation}>
                    <SweetAlert
                        custom
                        customIcon={Warning}
                        show
                        closeOnClickOutside={false}
                        confirmBtnText={
                            <FormattedMessage id="common.button.ok" />
                        }
                        title={<FormattedMessage id="button.success" />}
                        onConfirm={() => {
                            closeDeleteConversationSuccess()
                        }}
                        onCancel={this.toggleConfirmationDelete}
                        confirmBtnCssClass={"btn-orange"}
                        cancelBtnCssClass={"btn-black"}
                    >
                        <FormattedMessage id="directMessage.deleteSucces" />
                    </SweetAlert>
                </Modal>

                <Modal hideBackdrop open={confirmOpen}>
                    <SweetAlert
                        custom
                        customIcon={Warning}
                        show
                        showCancel
                        closeOnClickOutside={false}
                        confirmBtnText={
                            <FormattedMessage id="common.button.yes" />
                        }
                        cancelBtnText={
                            <FormattedMessage id="common.button.no" />
                        }
                        title={<FormattedMessage id="common.button.confirm" />}
                        onConfirm={() => {
                            this.toggleConfirmationOpen();
                            this.props.openConversationMessage(conversationId);
                        }}
                        onCancel={this.toggleConfirmationOpen}
                        confirmBtnCssClass={"btn-orange"}
                        cancelBtnCssClass={"btn-black"}
                    >
                        <FormattedMessage id="directMessage.confirmOpen" />
                    </SweetAlert>
                </Modal>

                <Modal hideBackdrop open={warningMaxSize}>
                    <SweetAlert
                        custom
                        customIcon={Warning}
                        show
                        closeOnClickOutside={false}
                        confirmBtnText={
                            <FormattedMessage id="common.button.yes" />
                        }
                        title={<FormattedMessage id="button.warning" />}
                        onConfirm={() => {
                            this.toggleWarningMaxSize();
                        }}
                        confirmBtnCssClass={"btn-orange"}
                    >
                        <FormattedMessage id="message.file.max" />
                    </SweetAlert>
                </Modal>
                
                <Modal hideBackdrop open={warningMaxDuration}>
                    <SweetAlert
                        custom
                        customIcon={Warning}
                        show
                        closeOnClickOutside={false}
                        confirmBtnText={
                            <FormattedMessage id="common.button.yes" />
                        }
                        title={<FormattedMessage id="button.warning" />}
                        onConfirm={() => {
                            this.toggleWarningMaxDuration();
                        }}
                        confirmBtnCssClass={"btn-orange"}
                    >
                        <FormattedMessage id="message.file.duration" />
                    </SweetAlert>
                </Modal>
                
            </div>
        );
    }
}

const mapStateToProps = ({ directMessageReducer, conversationReducer }) => {
    const { conversationId, isLoading } = directMessageReducer;
    const { conversations } = conversationReducer;
    const currentConversation = conversations.filter(
        (conversation) => conversation.id === conversationId
    )[0];

    return {
        conversationId,
        block: currentConversation ? currentConversation.status : MyGig.conversation.OPEN_CONVERSATION,
        isLoading,
        showAlertDeleteConversation: conversationReducer.showAlertDeleteConversation
    };
};

const mapActionToProps = {
    createConversation,
    createDirectMessage,
    closeConversation,
    deleteConversation,
    closeDeleteConversationSuccess,
    openConversationMessage,
};

export default connect(mapStateToProps, mapActionToProps)(DetailConversation);
