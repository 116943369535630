/**
 * Search Form
 */
import React, { Component } from 'react';
import { Input } from 'reactstrap';
import MyGig from 'Constants/MyGig';
import { injectIntl } from 'react-intl';
import KeyCodes from 'Constants/KeyCodes';

class SearchForm extends Component {

	handleKeyupSearchInput(e) {
		const { currentPage, searchJob } = this.props;
		const { value } = this.input;

		const key = e.which || e.keyCode;

		if (!value) {
			return searchJob(value, currentPage);
		}

		if (key === KeyCodes.ENTER) {
			searchJob(value, currentPage);
		}
	}

	render() {
		const { currentPage, intl } = this.props;
		let disabled = true;
		const { postedPage, pendingPage, completedPage, filledPage } = MyGig.currentPage;

		if ([postedPage, pendingPage, completedPage, filledPage].includes(currentPage)) {
			disabled = false;
		}

		return (
			<div className="search-wrapper">
				<Input
					innerRef={ref => this.input = ref}
					disabled={disabled}
					type="search"
					className="search-input-lg"
					placeholder={intl.formatMessage({ id: "nudgeSeeker.placeholders.searchInput" })}
					onKeyUp={(e) => this.handleKeyupSearchInput(e)}
				/>
			</div>
		);
	}
}

export default injectIntl(SearchForm);
