import React, {Component, Fragment} from "react";
import moment from 'moment';
import Icon from "@material-ui/core/Icon";
import MyGig from "Constants/MyGig";

class TableSection extends Component {

    constructor(props) {
        super(props);
    }

    onTextChange(name, value) {
        this.setState({
            [name]: value
        });
    }

    toggleSwitch(name, value) {
        this.setState({
            [name]: value
        });
        this.forceUpdate();

    }

    componentDidMount() {
        const item = this.props.item;
    }


    render() {
        const { listJobOfEmployee } = this.props;
        return (

            <div className="mt-3">
                <div className="table-responsive">
                    <table className="table table-sm">
                        <thead className="table-borderless">
                        <tr className="line-nowrap">

                            <th scope="col">Provider</th>
                            <th scope="col">Location</th>
                            <th scope="col">Job type</th>
                            <th scope="col">Description</th>
                            <th scope="col">Status</th>
                            <th scope="col">Posted</th>
                            <th scope="col">Pay rate</th>
                            <th scope="col">Break</th>
                            <th scope="col">QTY</th>
                            <th scope="col">Filled</th>
                            <th scope="col">Schedule</th>
                        </tr>
                        </thead>
                        <tbody className="table-header">
                        {listJobOfEmployee.length > 0 && listJobOfEmployee.map(employee => (
                            <tr>
                                <td>{employee.provider_name}</td>
                                <td>{employee.location_name}</td>
                                <td>{employee.job_type}</td>
                                <td className={"max-description"}> {employee.description}</td>
                                <td>{employee.status}</td>
                                <td>{moment(employee.posted).format("DD/MM/YYYY")}</td>
                                <td><i className="fas fa-pound-sign"></i> {employee.payrate}</td>
                                <td>-</td>
                                <td>{employee.assignments_total}</td>
                                <td>{employee.assignments_filled}</td>
                                <td>
                                    {
                                        employee.schedule.map(schedule => (
                                            <Fragment>
                                                <p><b>From:</b> {moment.unix(schedule.start).format("DD/MM/YYYY - H:mm")}</p>
                                                <p><b>To:</b> {moment.unix(schedule.end).format("DD/MM/YYYY - H:mm")}</p>
                                            </Fragment>
                                        ))
                                    }
                                </td>

                            </tr>
                        ))}


                        </tbody>
                    </table>
                </div>
            </div>
        )
    }

}

export default TableSection;
